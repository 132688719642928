export const setPosthogSettings = ({ identify, group, values, user }) => {
  if (!window.posthog) return
  const _values = user
    ? {
        ...values,
        email: user?.email,
        ...(user?.user_system_id
          ? {
              billing_user_id: user?.user_system_id,
              is_billing_user: !!user?.user_system_id
            }
          : {}),
        dashboard_user_id: user?.id,
        created_at: user?.created_at,
        email_verified: user?.email_verified,
        language_id: user?.language_id,
        level: user?.level
      }
    : values
  if (group) {
    window.posthog?.group?.(group, identify, _values)
    return
  }
  if (identify) {
    window.posthog?.identify?.(identify, _values)
  }
}

export const setIntercomSettings = (values, user) => {
  let _settings = { ...window.intercomSettings, ...values }
  if (user) {
    _settings = {
      ..._settings,
      ...(user?.user_system_id
        ? {
            billing_user_id: user?.user_system_id,
            is_billing_user: !!user?.user_system_id
          }
        : {}),
      dashboard_user_id: user?.id,
      created_at: user?.created_at,
      email_verified: user?.email_verified,
      language_id: user?.language_id,
      level: user?.level
    }
  }
  const email = values?.email ?? window.intercomSettings?.email
  const isBlockUpdate = email?.includes('@ordering.co')
  if (!isBlockUpdate) {
    window.intercomSettings = _settings
    window?.Intercom?.('boot', _settings)
  }
}
