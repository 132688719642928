import styled, { css } from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
`

export const PaymentMethodsContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  flex: 1;
  ${({ isOpenWalletDetails }) => isOpenWalletDetails && css`
    max-width: 500px;
  `}
  h1 {
    color: ${props => props.theme.colors.headingColor};
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  > button {
    height: 44px;
    margin: 20px 0;
  }
  @media (min-width: 576px) {
    padding: 20px;
  }
`

export const PaymethodListWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  h2 {
    font-size: 16px;
    font-weight: 700;
  }
`

export const PaymethodOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  ${({ hasNoPlan }) => !hasNoPlan && css`
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.lightPrimary};
    }

    ${({ disabled }) => disabled && css`
      cursor: initial;
      svg {
        cursor: pointer;
      }
    `}

    ${({ active }) => active && css`
      background-color: ${props => props.theme.colors.lightPrimary};
      border-top: 1px solid ${props => props.theme.colors.primary};
      border-bottom: 1px solid ${props => props.theme.colors.primary};
    `}
  `}
  > svg {
    font-size: 13px;
    color: ${props => props.theme.colors.secundaryLight};
  }
`

export const PaymethodOption = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  color: ${props => props.theme.colors.headingColor};

  > svg {
    font-size: 20px;
    &.fill {
      color: ${props => props.theme.colors.primary};
    }
  }
`

export const PaymethodName = styled.span`
  color: ${props => props.theme.colors.headingColor};
  font-size: 14px;
  flex: 1;
  margin: 0 10px;
`
export const SearchBarWrapper = styled.div`
  margin-bottom: 15px;
  margin-top: 27px;
`
export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  button {
    height: 32px;
    font-size: 12px;
    &:first-child {
      ${props => props.theme?.rtl
    ? css`
        margin-left: 20px;
      `
    : css`
        margin-right: 20px;
      `}
    }
  }
`

export const Divider = styled.div`
  height: 10px;
  margin: 20px 0px;
  background-color: #F8F9FA;
`

export const CreditCardPaymentsContainer = styled.div`
  position: relative;
  ${({ isBlurried }) => isBlurried && css`
  button.btn-primary {
    background-color: ${props => props.theme.colors.primary};
  }

  > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    z-index: 2000;
    &:hover {
      background-color: ${props => props.theme.colors.lightPrimary};
    }
    &.btn-primary {
      &:hover {
        background-color: ${props => props.theme.colors.primary};
      }
    }

    span {
      ${props => props.theme?.rtl
      ? css`
        margin-right: 15px;
      `
      : css`
        margin-left: 15px;
      `}
    }

    svg {
      font-size: 20px;
    }
  }
    .upgrade-to-pro-button {
      background-color: #ffbf0094;
      &:hover {
        background-color: #ffc107;
      }
    }
  > div {
      filter: blur(1.5px);
    }
  `}
`

export const ContactAdminButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px 16px;
  align-items: center;
  color: #fff;
  background: #000;
  border-radius: 7.6px;
  z-index: 200;
`
