import React, { useEffect, useState } from 'react'
import { useLanguage, useConfig } from '../../../../../index'
import { Select } from '../../../styles/Select'
import { Input } from '../../../styles/Inputs'
import { capitalize } from '~ui'

import {
  SettingsSelectContainer,
  SelectHeader,
  SelectContent,
  Option,
  OptionsError,
  InputWrapper
} from './styles'

export const SettingsSelectUI = (props) => {
  const {
    config,
    handleSelectChange,
    noHeader
  } = props

  const [, t] = useLanguage()

  const [options, setOptions] = useState(null)
  const [{ configs }] = useConfig()
  const customKeyConfigs = ['filter_order_options', 'allowed_order_types_guest_checkout', 'default_order_type', 'order_types_allowed', 'unaddressed_order_types_allowed']

  useEffect(() => {
    if (!config) return
    let selectedTypes = []
    if (config.key === 'terms_and_conditions') {
      selectedTypes = [
        { value: 'true', content: <Option>{t('YES', 'Yes')}</Option> },
        { value: 'false', content: <Option>{t('NO', 'No')}</Option> }
      ]
    } else if (customKeyConfigs.includes(config.key)) {
      const isProjectProPlan = configs?.plan_pro && configs?.plan_pro?.value
      const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value
      const isAddonCatering = configs?.addon_catering?.value
      const isAddonReservation = configs?.addon_reservation?.value

      const defaultOrderType = ['delivery', 'pickup']

      const proOrderTypes = ['eatin', 'curbside', 'drivethru']
      const enterpriseOrderTypes = ['seatdelivery']
      const addonsCateringOrderTypes = ['catering_delivery', 'catering_pickup']
      const addonsReservationOrderTypes = ['reservation']

      if (isProjectProPlan || isProjectEnterpricePlan) defaultOrderType.push(...proOrderTypes)
      if (isProjectEnterpricePlan) defaultOrderType.push(...enterpriseOrderTypes)
      if (isAddonCatering) defaultOrderType.push(...addonsCateringOrderTypes)
      if (isAddonReservation) defaultOrderType.push(...addonsReservationOrderTypes)

      selectedTypes = config?.options?.filter(option => defaultOrderType.includes(option.value)).map(item => {
        return { value: item.value, content: <Option>{t(item.text.toUpperCase(), capitalize(item.text.toLowerCase().replaceAll('_', ' ')))}</Option> }
      })
    } else {
      selectedTypes = config?.options?.map(item => {
        return { value: item.value, content: <Option>{t(item.text.toUpperCase(), capitalize(item.text.toLowerCase().replaceAll('_', ' ')))}</Option> }
      })
    }
    if (config.customizable) {
      selectedTypes.push({
        value: config?.value,
        content: <Option>{t('CUSTOM', 'Custom')}</Option>,
        showOnSelected: (
          <InputWrapper className='open-disabled'>
            <Input
              placeholder={t('CUSTOM', 'Custom')}
              defaultValue={config?.value}
              onChange={e => handleSelectChange(e.target.value)}
            />
          </InputWrapper>
        )
      })
    }
    setOptions(selectedTypes)
  }, [config])

  return (
    <SettingsSelectContainer>
      {!noHeader && (
        <SelectHeader>
          {
            config?.name && (
              <label>{config?.name}</label>
            )
          }
          {config?.description && <p>{config?.description}</p>}
        </SelectHeader>
      )}
      {
        options
          ? (
          <SelectContent>
            <Select
              selectStyle={props.selectStyle}
              defaultValue={config?.value}
              options={options}
              onChange={(typeValue) => handleSelectChange(typeValue)}
              placeholder={t('SELECT_A_OPTION', 'Select a option')}
            />
          </SelectContent>
            )
          : (
          <OptionsError>{t('NO_OPTIONS_VALUE', 'There is no options value')}</OptionsError>
            )
      }

    </SettingsSelectContainer>
  )
}
