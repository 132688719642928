import React, { useEffect } from 'react'
import { useSession } from '~components'
import { setIntercomSettings, setPosthogSettings } from '../Utils'
import settings from '../../config.json'

export const IntercomWrapper = (props) => {
  const [{ user, auth, token }] = useSession()

  const values = {
    user_id: user?.email,
    email: user?.email
  }

  if (user?.first_name || user?.last_name) {
    values.name = user?.first_name && user?.last_name
      ? `${user?.first_name} ${user?.last_name}`
      : user?.first_name ?? user?.last_name
  }

  if (user?.phone_country_code && user?.cellphone) {
    values.phone = `+${user?.phone_country_code} ${user?.cellphone}`
  }

  const addIntercomScript = () => {
    const isBlockUpdate = user?.email?.includes('@ordering.co')
    if (!isBlockUpdate) {
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'vyua4cs5',
        ...values
      }
      const func = (function () { const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings) } else { const d = document; const i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/vyua4cs5'; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x) }; if (document.readyState === 'complete') { l() } else if (w.attachEvent) { w.attachEvent('onload', l) } else { w.addEventListener('load', l, false) } } })()
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.text = func
      script.id = 'intercom_widget'
      document.body.appendChild(script)
    }
  }

  const getIntercomHash = async ({ userId, token }) => {
    if (!userId || !token) return null
    try {
      const req = await fetch(`${settings.api.url}/${settings.api.version}/system/integrations/intercom/hash`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ user_email: userId })
      })
      const { error, result } = await req.json()
      const userHash = error ? null : result?.intercom_hash
      if (userHash) {
        setIntercomSettings({ user_hash: userHash })
      }
    } catch {
      return null
    }
  }

  useEffect(() => {
    if (!auth) {
      window?.Intercom?.('shutdown')
    } else if (user?.level === 0) {
      setIntercomSettings(values, user)
    }
    if (auth) {
      setPosthogSettings({ identify: user.email, values, user })
    }
    if (window.document.getElementById('intercom_widget') || !auth || user?.level !== 0) {
      return
    }
    addIntercomScript()
  }, [user, auth])

  useEffect(() => {
    if (auth) {
      getIntercomHash({ userId: user?.email, token })
    }
  }, [auth])

  return <>{props.children}</>
}
