import React from 'react'
import {
  ShopWindow as BusinessIcon,
  Truck as DriverIcon
} from 'react-bootstrap-icons'

export const invoiceOptionValues = (props) => {
  const { t, isProjectProPlan, isProjectEnterpricePlan } = props
  const values = [
    {
      id: 1,
      key: 'business',
      name: t('BUSINESS_INVOICE', 'Business invoice'),
      icon: <BusinessIcon />,
      description: t('BUSINESS_INVOICE_DESCRIPTION', 'Business invoice description'),
      enabled: true
    },
    {
      id: 2,
      key: 'driver',
      name: t('DRIVER_INVOICE', 'Driver invoice'),
      icon: <DriverIcon />,
      description: t('DRIVER_INVOICE_DESCRIPTION', 'Driver invoice description'),
      enabled: (isProjectProPlan || isProjectEnterpricePlan)
    }
  ]
  return values.filter((option) => (option.enabled))
}
