import React from 'react'
import { Option } from './styles'
export const productTypes = (t, isProjectProPlan, isProjectEnterpricePlan, addons) => {
  const productTypeOptions = [
    {
      value: 'website',
      content: <Option>{t('TYPE_WEBSITE', 'Website')}</Option>,
      enabled: true
    },
    {
      value: 'ordering_app',
      content: <Option>{t('TYPE_ORDERING_APP', 'Ordering App')}</Option>,
      enabled: isProjectProPlan || isProjectEnterpricePlan
    },
    {
      value: 'dashboard',
      content: <Option>{t('TYPE_DASHBOARD', 'Dashboard')}</Option>,
      enabled: isProjectEnterpricePlan || addons?.web_dashboard_x
    },
    {
      value: 'franchise_app',
      content: <Option>{t('TYPE_FRANCHISE_APP', 'Franchise App')}</Option>,
      enabled: isProjectEnterpricePlan
    },
    {
      value: 'api',
      content: <Option>{t('TYPE_API', 'API')}</Option>,
      enabled: isProjectEnterpricePlan
    },
    {
      value: 'business_app',
      content: <Option>{t('TYPE_BUSINESS_APP', 'Business App')}</Option>,
      enabled: isProjectEnterpricePlan || addons?.app_business_x
    },
    {
      value: 'driver_app',
      content: <Option>{t('TYPE_DRIVER_APP', 'Driver App')}</Option>,
      enabled: isProjectEnterpricePlan || addons?.app_driver_x
    }
  ]
  return productTypeOptions.filter((option) => (option.enabled))
}
