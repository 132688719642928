export const defaultStateOptions = {
  selectedCategory: null,
  openDefaultSettings: null,
  openConfigSettings: false,
  openSitesAuthSettings: false,
  openMultiCountrySettings: false,
  openUserInformation: false,
  openLanguageManager: false,
  openCardSettings: false
}

export const getCategorySetting = (categoryId) => {
  const settingOptionsDiccionary = {
    sites: { openSitesAuthSettings: true },
    multi_country: { openMultiCountrySettings: true },
    user_information: { openUserInformation: true },
    language_manager: { openLanguageManager: true }
  }
  return {
    propToUpdate: settingOptionsDiccionary?.[categoryId] ?? { openDefaultSettings: categoryId },
    arg: categoryId
  }
}
