import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController, SettingsMenuOptionDetails as SettingsMenuOptionController } from '~ui'

export const EnterpriseIntegrationsSettings = (props) => {
  const [, t] = useLanguage()
  const isUseFilterComponent = props?.useFilterComponent ?? false
  let enterpriseIntegrationsSettingsProps = {
    ...props,
    settingsType: 'key_enterprise'
  }
  if (isUseFilterComponent) {
    const filterProps = {
      allowOptions: [],
      pageURL: 'enterprise_settings',
      title: t('ENTERPRISE_INTEGRATIONS', 'Enterprise integrations'),
      description: t('ENTERPRISE_INTEGRATIONS_DESCRIPTION', 'Enterprise integrations')
    }
    enterpriseIntegrationsSettingsProps = {
      ...enterpriseIntegrationsSettingsProps,
      ...filterProps
    }
  }

  const ControllerComponent = isUseFilterComponent ? FilterSettingsController : SettingsMenuOptionController

  return (
    <>
      <HelmetTags page='enterprise_settings' />
      <ControllerComponent {...enterpriseIntegrationsSettingsProps} />
    </>
  )
}
