import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { RewardsPrograms as RewardsProgramsController } from '~ui'

export const CashWalletsRefunds = (props) => {
  const cashWalletsRefundsProps = {
    ...props,
    isWalletRefunds: true,
    settingsType: 'addon_cash_wallet_and_refunds'
  }

  return (
    <>
      <HelmetTags page='wallets_refunds' />
      <RewardsProgramsController {...cashWalletsRefundsProps} />
    </>
  )
}
