import React, { useState, createContext, useEffect } from 'react'
import { useApi, useSession } from '../../../../index'
import { setPosthogSettings } from '~/components/Utils'
/**
 * Create ProjectContext
 * This context will manage the info between pages and provide an easy interface
 */
export const ProjectContext = createContext()

/**
  * Custom provider to mange shared info
  * @param {props} props
  */
export const ProjectProvider = ({ children }) => {
  const [ordering] = useApi()
  const [{ user, token }] = useSession()
  const [state, setState] = useState({ loading: false, project: {} })

  const name = (user?.name || user?.lastname)
    ? `${user?.name ?? ''}${user?.lastname ? ` ${user?.lastname}` : ''}`
    : null

  const phone = (user?.country_phone_code || user?.cellphone)
    ? `${user?.country_phone_code ?? ''}${user?.cellphone ? `${user?.country_phone_code ? ' ' : ''}${user?.cellphone}` : ''}`
    : null

  const getProjectStatus = async () => {
    if (state.loading) return
    try {
      setState({ ...state, loading: true })
      const response = await fetch(`${ordering.root}/current`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      const reqPlan = await fetch(`${ordering.root}/billing/subscriptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      const { error, result } = await response.json()
      const resPlan = await reqPlan.json()

      if (!error) {
        setPosthogSettings({
          identify: user.email,
          user,
          values: {
            ...(name ? { name } : {}),
            ...(phone ? { phone } : {})
          }
        })
      }
      const activePlan = resPlan?.result?.length ? resPlan?.result?.find(plan => plan?.status === 'active')?.plan?.plan : null

      setState({
        loading: false,
        project: error ? {} : result,
        plan: resPlan?.error
          ? null
          : activePlan
      })
    } catch (error) {
      setState({ ...state, loading: false })
    }
  }

  const functions = {
    getProjectStatus
  }

  useEffect(() => {
    if (token) {
      getProjectStatus()
    }
  }, [token])

  return (
    <ProjectContext.Provider value={[state, functions]}>
      {children}
    </ProjectContext.Provider>
  )
}

export function useProjectState () {
  const projectManager = React.useContext(ProjectContext)
  return projectManager || [{}, () => {}]
}
