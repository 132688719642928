import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BsChevronRight from '@meronex/icons/bs/BsChevronRight'
import { useTheme } from 'styled-components'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { XLg, ThreeDots, Laptop, Phone, ArrowsAngleExpand, ArrowsAngleContract, BoxArrowUpRight } from 'react-bootstrap-icons'
import { useLanguage, useUtils, useEvent, useApi, useConfig, useSession } from '~components'
import { Button, IconButton, Switch, Input } from '../../../styles'
import { Confirm, Modal, Alert, useWindowSize } from '~ui'
import { SnoozeComponent } from '../SnoozeComponent'
import { BusinessPreview } from '../BusinessPreview'

import {
  BusinessDetailsContainer,
  DetailsHeader,
  BusinessName,
  LeftHeader,
  RightHeader,
  BusinessHeader,
  BusinessLogo,
  BusinessDetailsContent,
  BusinessDescription,
  BusinessConfigsContainer,
  BusinessConfigItem,
  ActionSelectorWrapper,
  BusinessPreviewHeader,
  ButtonWrapper
} from './styles'
import { checkSiteUrl } from '../../../utils'

export const BusinessSummary = (props) => {
  const {
    isAdmin,
    businessState,
    actionSidebar,
    handleChangeActiveBusiness,
    selectedItem,
    handleSelectedItem,
    handleDuplicateBusiness,
    handleDeleteBusiness,
    handleUpdateBusinessClick,
    extraOpen,
    spoonityConfig,
    siteState,
    isExpand,
    setIsExpand,
    formState,
    setFormState
  } = props

  const [, t] = useLanguage()
  const [{ optimizeImage }] = useUtils()
  const [events] = useEvent()
  const [{ configs }] = useConfig()

  const theme = useTheme()
  const [ordering, { configurations }] = useApi()
  const { width } = useWindowSize()
  const [isBusinessPreview, setIsBusinessPreview] = useState(false)
  const [isBusinessSnooze, setIsBusinessSnooze] = useState(false)
  const [selectedView, setSelectedView] = useState('desktop')
  const [sessionState] = useSession()
  const [confirm, setConfirm] = useState({ open: false, content: null, handleOnAccept: null })
  const [alertState, setAlertState] = useState({ open: false, content: [], success: false })

  const isEnabledWhiteLabelModule = configs?.white_label_module?.value
  const isAllowRegisteredBusiness = ((sessionState?.user?.level === 0) || (sessionState?.user?.level === 2 && configs?.allow_business_owner_register_business?.value === '1'))
  const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value
  const isAddonCatering = configs?.addon_catering?.value
  const isShowMetafields = configs?.show_metafields && (configs?.show_metafields?.value || configs?.show_metafields?.value === '1')
  const isReservationsEnabled = configs?.reservation_enabled?.value === '1'
  let timeout = null

  const handleOpenCategory = () => {
    events.emit('go_to_page', { page: 'store', params: { store: businessState?.business?.slug } })
  }

  const handleOpenSite = () => {
    const storeUrl = siteState?.site?.domain && siteState?.site?.ssl_process_status === 'ended'
      ? `https://${siteState?.site?.domain?.replace?.('tryordering', configurations?.subdomain)}/store/${businessState?.business?.slug}`
      : configs?.site_url?.value?.replace?.('tryordering', configurations?.subdomain)
        ? `${checkSiteUrl(configs?.site_url?.value?.replace?.('tryordering', configurations?.subdomain))}store/${businessState?.business?.slug}`
        : `https://${ordering.project}.${configurations?.subdomain}.com/store/${businessState?.business?.slug}`
    window.open(storeUrl, '_blank')
  }

  const itemsExcluded = spoonityConfig ? ['publishing', 'personalization', 'logs'] : ['publishing', 'spoonity_key', 'personalization', 'logs']

  const isEnabled = (key) => {
    if (isEnabledWhiteLabelModule) {
      return !['personalization', 'sales_channels'].includes(key)
    } else {
      if (isAdmin) {
        return true
      } else {
        return !itemsExcluded.includes(key)
      }
    }
  }

  const changeCustomSlug = (slug) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (slug === '') {
        setAlertState({
          ...alertState,
          open: true,
          content: [t('SLUG_REQUIRED', 'Slug is required')]
        })
        return
      }
      setFormState({
        ...formState,
        changes: { slug }
      })
    }, 750)
  }

  const closeAlert = () => {
    setAlertState({
      ...alertState,
      open: false,
      content: []
    })
  }
  const businessConfigs = [
    {
      key: 'store_details',
      value: t('STORE_DETAILS', 'Store details'),
      enabled: isEnabled('store_details')
    },
    {
      key: 'schedule',
      value: t('SCHEDULE', 'Schedule'),
      enabled: isEnabled('schedule')
    },
    {
      key: 'menu',
      value: t('MENU_V21', 'Menu'),
      enabled: isEnabled('menu')
    },
    {
      key: 'delivery_pickup_more',
      value: t('DELIVERY_PICKUP_MORE', 'Delivery, pickup & more'),
      enabled: isEnabled('delivery_pickup_more')
    },
    {
      key: 'notifications',
      value: t('NOTIFICATIONS', 'Notifications'),
      enabled: isEnabled('notifications')
    },
    {
      key: 'payment_methods',
      value: t('PAYMENTS_METHODS', 'Payment methods'),
      enabled: isEnabled('payment_methods')
    },
    {
      key: 'tax_fees',
      value: t('TAX_AND_FEES', 'Tax & fees'),
      enabled: isEnabled('tax_fees')
    },
    {
      key: 'promotions',
      value: t('PROMOTIONS', 'Promotions'),
      enabled: isEnabled('promotions')
    },
    {
      key: 'preorder',
      value: t('PREORDERS_SETTING', 'Preorders and lead times'),
      enabled: isEnabled('preorder') && isAddonCatering
    },
    {
      key: 'custom_fields',
      value: t('CUSTOM_FIELDS', 'Custom fields'),
      enabled: isEnabled('custom_fields') && isShowMetafields
    },
    {
      key: 'personalization',
      value: t('PERSONALIZATION', 'Personalization'),
      enabled: false
    },
    {
      key: 'ordering_channels',
      value: t('ORDERING_CHANNELS', 'Ordering channels'),
      enabled: isEnabled('ordering_channels')
    },
    {
      key: 'publishing',
      value: t('QR_CODES', 'QR Codes'),
      enabled: isEnabled('publishing')
    },
    {
      key: 'sales_channels',
      value: t('SALES_CHANNELS', 'Sales channels'),
      enabled: isEnabled('sales_channels')
    },
    {
      key: 'layout',
      value: t('LAYOUT', 'Layout'),
      enabled: isEnabled('layout')
    },
    {
      key: 'webhooks',
      value: t('WEBHOOKS', 'Webhooks'),
      enabled: isEnabled('webhooks')
    },
    {
      key: 'places',
      value: t('PLACES', 'Places'),
      enabled: false
    },
    {
      key: 'spoonity_key',
      value: t('SPOONITY_KEY', 'Spoonity key'),
      enabled: spoonityConfig && isProjectEnterpricePlan
    },
    {
      key: 'logs',
      value: t('LOGS', 'Logs'),
      enabled: isProjectEnterpricePlan && isEnabled('logs')
    },
    {
      key: 'reservations',
      value: t('RESERVATIONS', 'Reservations'),
      enabled: isReservationsEnabled
    }
  ]

  const onClickDeleteBusiness = () => {
    setConfirm({
      open: true,
      content: t('QUESTION_DELETE_BUSINESS', 'Are you sure that you want to delete this business?'),
      handleOnAccept: () => {
        setConfirm({ ...confirm, open: false })
        handleDeleteBusiness()
      }
    })
  }

  const expandSideBar = () => {
    const element = document.getElementById('business_details_bar')
    if (!element) return

    if (isExpand) element.style.width = '500px'
    else element.style.width = '100vw'
    setIsExpand(prev => !prev)
  }

  useEffect(() => {
    if (formState.loading || Object.keys(formState?.changes).length === 0 || extraOpen) return
    handleUpdateBusinessClick()
  }, [formState?.changes?.slug, extraOpen])

  return (
    <>
      <BusinessDetailsContainer>
        <DetailsHeader>
          <LeftHeader>
            {businessState?.loading
              ? (
              <BusinessName>
                <Skeleton width={100} />
              </BusinessName>
                )
              : (
              <BusinessName>
                {businessState?.business?.name}
              </BusinessName>
                )}
            {businessState?.loading
              ? (
              <Skeleton width={50} />
                )
              : (
              <Switch
                defaultChecked={businessState?.business?.enabled}
                onChange={handleChangeActiveBusiness}
              />
                )}
          </LeftHeader>
          <RightHeader>
            {width > 576 && !extraOpen && (
              <IconButton
                color='black'
                onClick={() => expandSideBar()}
              >
                {isExpand ? <ArrowsAngleContract /> : <ArrowsAngleExpand />}
              </IconButton>
            )}
            <ActionSelectorWrapper>
              <DropdownButton
                menuAlign={theme?.rtl ? 'left' : 'right'}
                title={<ThreeDots />}
                id={theme?.rtl ? 'dropdown-menu-align-left' : 'dropdown-menu-align-right'}
              >
                <Dropdown.Item
                  onClick={() => setIsBusinessPreview(true)}
                >
                  {t('PREVIEW', 'Preview')}
                </Dropdown.Item>
                {isAllowRegisteredBusiness &&
                  <Dropdown.Item
                    onClick={() => handleDuplicateBusiness()}
                  >
                    {t('DUPLICATE', 'Duplicate')}
                  </Dropdown.Item>
                }
                {/* {!isEnabledWhiteLabelModule && (
                  <Dropdown.Item
                    onClick={() => handleSelectedItem('personalization')}
                  >
                    {t('PERSONALIZATION', 'Personalization')}
                  </Dropdown.Item>
                )} */}
                <Dropdown.Item
                  onClick={() => handleSelectedItem('custom_fields')}
                >
                  {t('CUSTOM_FIELDS', 'Custom fields')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClickDeleteBusiness()}
                >
                  {t('DELETE', 'Delete')}
                </Dropdown.Item>
              </DropdownButton>
            </ActionSelectorWrapper>
            <IconButton
              color='black'
              onClick={() => actionSidebar(false)}
            >
              <XLg />
            </IconButton>
          </RightHeader>
        </DetailsHeader>
        {businessState?.loading
          ? (
          <BusinessHeader isSkeleton>
            <BusinessLogo>
              <Skeleton width={60} height={60} />
            </BusinessLogo>
          </BusinessHeader>
            )
          : (
          <BusinessHeader bgimage={optimizeImage(businessState?.business?.header, 'h_200,c_limit')}>
            <BusinessLogo bgimage={optimizeImage(businessState?.business?.logo || theme.images?.dummies?.businessLogo, 'h_100,c_limit')} />
          </BusinessHeader>
            )}

        <BusinessDetailsContent>
          <ButtonWrapper flexEnd>
            <Button
              color='lightGreen'
              borderRadius='8px'
              onClick={() => setIsBusinessSnooze(true)}
              disabled={businessState?.loading}
            >
              {t('SNOOZE', 'Snooze')}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              color='lightPrimary'
              borderRadius='8px'
              onClick={handleOpenCategory}
              disabled={businessState?.loading}
            >
              {t('CATEGORIES_AND_PRODUCTS', 'Categories & products')}
            </Button>
            <Input
              type='text'
              defaultValue={businessState?.business?.slug || ''}
              placeholder={t('SLUG', 'Slug')}
              onChange={(e) => changeCustomSlug(e.target.value)}
              onKeyPress={(e) => {
                if (!/^[0-9a-zA-Z]$/.test(e.key)) {
                  e.preventDefault()
                }
              }}
            />
            {!isEnabledWhiteLabelModule && (
              <IconButton
                className='redirect-website'
                disabled={businessState?.loading}
                onClick={handleOpenSite}
              >
              <BoxArrowUpRight />
            </IconButton>
            )}
          </ButtonWrapper>
          <BusinessDescription>
            {businessState?.loading
              ? (
              <Skeleton width={300} />
                )
              : (
                  businessState?.business?.description
                )}
          </BusinessDescription>
          <BusinessConfigsContainer isLoading={businessState?.loading}>
            {businessConfigs.map(config => (
              config.enabled && (
                <BusinessConfigItem
                  key={config.key}
                  active={selectedItem === config.key}
                  onClick={() => handleSelectedItem(config.key)}
                >
                  <span>{config.value}</span>
                  <BsChevronRight />
                </BusinessConfigItem>
              ))
            )}
          </BusinessConfigsContainer>
        </BusinessDetailsContent>
      </BusinessDetailsContainer>
      <Confirm
        width='700px'
        title={t('WEB_APPNAME', 'Ordering')}
        content={confirm.content}
        acceptText={t('ACCEPT', 'Accept')}
        open={confirm.open}
        onClose={() => setConfirm({ ...confirm, open: false })}
        onCancel={() => setConfirm({ ...confirm, open: false })}
        onAccept={confirm.handleOnAccept}
        closeOnBackdrop={false}
      />
      <Alert
        title={t('BUSINESS', 'Business')}
        content={alertState.content}
        acceptText={t('ACCEPT', 'Accept')}
        open={alertState.open}
        onClose={() => closeAlert()}
        onAccept={() => closeAlert()}
        closeOnBackdrop={false}
      />
      {isBusinessPreview && (
        <Modal
          width='900px'
          open={isBusinessPreview}
          onClose={() => {
            setIsBusinessPreview(false)
            setSelectedView('desktop')
          }}
        >
          <BusinessPreviewHeader>
            <h1>{t('PREVIEW', 'Preview')}</h1>
            <div>
              <IconButton
                color={selectedView === 'desktop' ? 'primary' : 'black'}
                onClick={() => setSelectedView('desktop')}
              >
                <Laptop />
              </IconButton>
              <IconButton
                color={selectedView === 'mobile' ? 'primary' : 'black'}
                onClick={() => setSelectedView('mobile')}
              >
                <Phone />
              </IconButton>
            </div>
          </BusinessPreviewHeader>
          <BusinessPreview
            isMobileView={selectedView === 'mobile'}
            business={businessState?.business}
          />
        </Modal>
      )}
      <Modal
        width='85%'
        maxWidth='1000px'
        open={isBusinessSnooze}
        onClose={() => setIsBusinessSnooze(false)}
        closeOnBackdrop={false}
      >
        <SnoozeComponent
          dataState={businessState?.business}
          handleUpdate={handleUpdateBusinessClick}
          setFormState={setFormState}
          formState={formState}
          onClose={() => setIsBusinessSnooze(false)}
        />
      </Modal>
    </>
  )
}
