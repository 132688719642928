import styled, { css } from 'styled-components'

export const Container = styled.div`
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 25px;
    sup {
      color: ${props => props.theme.colors.danger};
      font-size: 13px;
    }
  }
`

export const ContentWrapper = styled.div`
  overflow: auto;
  max-height: 500px;
`

export const PaymethodItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  cursor: pointer;
  ${({ isBorderTop }) => isBorderTop && css`
    border-top: 1px solid ${props => props.theme.colors.borderColor};
  `}

  svg {
    font-size: 16px;
    color: ${props => props.theme.colors.secundaryLight};

    &.enabled {
      color: ${props => props.theme.colors.primary};
    }
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 20px;
    ${props => props.theme.rtl && css`
      margin-left: 0px;
      margin-right: 20px;
    `}
  }
`

export const Divider = styled.div`
  height: 10px;
  margin: 20px 0px;
  background-color: #F8F9FA;
`

export const CreditCardPaymentsContainer = styled.div`
  position: relative;
  ${({ isBlurried }) => isBlurried && css`
  button.btn-primary {
    background-color: ${props => props.theme.colors.primary};
  }

  > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    z-index: 2000;
    &:hover {
      background-color: ${props => props.theme.colors.lightPrimary};
    }
    &.btn-primary {
      &:hover {
        background-color: ${props => props.theme.colors.primary};
      }
    }

    span {
      ${props => props.theme?.rtl
      ? css`
        margin-right: 15px;
      `
      : css`
        margin-left: 15px;
      `}
    }

    svg {
      font-size: 20px;
    }
  }
    .upgrade-to-pro-button {
      background-color: #ffbf0094;
      &:hover {
        background-color: #ffc107;
      }
    }
  > div {
      filter: blur(1.5px);
    }
  `}
`

export const ContactAdminButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px 16px;
  align-items: center;
  color: #fff;
  background: #000;
  border-radius: 7.6px;
  z-index: 200;
`
