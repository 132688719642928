import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { List as MenuIcon, InfoCircle } from 'react-bootstrap-icons'

import { useLanguage, Settings as SettingsController } from '../../../../../index'
import { NotFoundSource } from '../../Shared'

import { EmailSetting } from '../EmailSetting'
import { NotificationSetting } from '../NotificationSetting'
import { SettingsList } from '../SettingsList'
import { IconButton } from '../../../styles'
import { useInfoShare } from '../../../contexts/InfoShareContext'
import { CheckoutFieldsSetting } from '../CheckoutFieldsSetting'
import { AddressFieldsSetting } from '../AddressFieldsSetting'
import { LanguageSetting } from '../LanguageSetting'
import { SitesAuthSettings } from '../SitesAuthSettings'
import { MultiCountrySettings } from '../MultiCountrySettings'
import { CardFieldsSetting } from '../CardFieldsSetting'
import { PlaceListing } from '../PlaceListing'
import { LanguageManager } from '../LanguageManager'
import { IntegrationListing } from '../IntegrationListing'
import { GuestCheckoutFieldsSetting } from '../GuestCheckoutFieldsSetting'
import { Cms } from '../Cms'
import { InfoHelpBox } from '../InfoHelpBox'
import { defaultStateOptions, getCategorySetting } from './utils'

import {
  BasicSettingsContainer,
  HeaderTitleContainer,
  ContentWrapper,
  SubCategoryWrapper,
  CategoryName,
  Description,
  Content,
  InfoWrapper,
  CategoryNameContainer
} from './styles'

const SettingsMenuOptionDetailsUI = (props) => {
  const {
    categoryList,
    isHideSideMenu,
    subCategoryList,
    sidebarSettingContainer
  } = props

  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const [, t] = useLanguage()
  const location = useLocation()
  const containerRef = useRef(null)

  const [{ isCollapse }, { handleMenuCollapse }] = useInfoShare()

  const [openSettingOption, setOpenSettingOption] = useState(defaultStateOptions)
  const [openMoreInfo, setOpenMoreInfo] = useState({ open: false, category: null })

  const userInformationProps = {
    avoidExpand: true,
    sidebarSettingContainer
  }

  const handleChangeSettingOption = (propToUpdate = {}, ignoreHistory = false, arg) => {
    const params = arg ? `?category=${arg}` : ''

    setOpenSettingOption({
      ...openSettingOption,
      ...defaultStateOptions,
      ...propToUpdate
    })
    !ignoreHistory && history.replace(`${location.pathname}${params}`)
  }

  useEffect(() => {
    if (categoryList.loading) return
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0)
    }
    handleChangeSettingOption({}, true)
    const categoryId = query.get('category')
    if (categoryId) {
      if (isNaN(Number(categoryId))) {
        handleChangeSettingOption(
          getCategorySetting(categoryId)?.propToUpdate,
          true,
          getCategorySetting(categoryId)?.arg
        )
      } else {
        const categorySelected = categoryList?.categories.find(item => item?.id === parseInt(categoryId))
        if (categorySelected) {
          handleChangeSettingOption({
            selectedCategory: categorySelected,
            openConfigSettings: true
          }, true, categorySelected?.id)
        }
      }
    } else {
      const categorySelected = categoryList?.categories[0]
      handleChangeSettingOption({
        selectedCategory: categorySelected,
        openConfigSettings: true
      }, false, categorySelected?.id)
    }
  }, [categoryList.loading, location.search, categoryList?.categories])

  return (
    <>
      <BasicSettingsContainer ref={containerRef} disableShadow={openSettingOption.selectedCategory?.configs?.length === 0}>
        <div className='shadow-main-container' >
          <HeaderTitleContainer>
            {isCollapse && !isHideSideMenu && (
              <IconButton
                color='black'
                onClick={() => handleMenuCollapse(false)}
              >
                <MenuIcon />
              </IconButton>
            )}
            {openSettingOption.selectedCategory && (
              <Content>
                <CategoryName>
                  <CategoryNameContainer>
                    <p>{openSettingOption.selectedCategory.name}</p>
                    <InfoWrapper>
                      <IconButton
                        color='primary'
                        onClick={() => setOpenMoreInfo({ open: true, category: openSettingOption.selectedCategory })}
                      >
                        <InfoCircle />
                      </IconButton>
                    </InfoWrapper>
                  </CategoryNameContainer>
                </CategoryName>
                <Description>{openSettingOption.selectedCategory?.description}</Description>
              </Content>
            )}
          </HeaderTitleContainer>
          <ContentWrapper className='row'>
            {openSettingOption.openDefaultSettings === 'languages' && (
              <LanguageSetting />
            )}
            {openSettingOption.openDefaultSettings === 'places' && (
              <PlaceListing avoidExpand />
            )}
            {openSettingOption.openDefaultSettings === 'developers' && (
              <IntegrationListing avoidExpand />
            )}
            {openSettingOption.openDefaultSettings === 'pages' && (
              <Cms avoidExpand />
            )}
            {openSettingOption.openDefaultSettings === 'checkout_fields' && (
              <GuestCheckoutFieldsSetting disableGuestColumns />
            )}
            {openSettingOption.openUserInformation && (
              <>
                <CheckoutFieldsSetting {...userInformationProps} />
                <AddressFieldsSetting {...userInformationProps} />
                <CardFieldsSetting {...userInformationProps}/>
              </>
            )}
            {openSettingOption.openLanguageManager && (
              <LanguageManager avoidExpand isShowProductType />
            )}
            {openSettingOption.openSitesAuthSettings && (
              <SitesAuthSettings avoidExpand />
            )}
            {openSettingOption.openMultiCountrySettings && (
              <MultiCountrySettings />
            )}
            {openSettingOption.openConfigSettings && openSettingOption.selectedCategory?.configs?.length > 0 && (
              <SubCategoryWrapper>
                {(openSettingOption.selectedCategory?.key === 'email_configs') && (
                  <EmailSetting
                    {...props}
                    category={openSettingOption.selectedCategory}
                    onCloseSettingsList={() => (null)}
                    isRemoveGeneralTitle
                  />
                )}
                {openSettingOption.selectedCategory?.key === 'notification' && (
                  <NotificationSetting
                    {...props}
                    category={openSettingOption.selectedCategory}
                    onCloseSettingsList={() => (null)}
                    isRemoveGeneralTitle
                  />
                )}
                {(openSettingOption.selectedCategory?.key !== 'email_configs' && openSettingOption.selectedCategory?.key !== 'notification') && (
                  <SettingsList
                    {...props}
                    category={openSettingOption.selectedCategory}
                    onCloseSettingsList={() => (null)}
                    isRemoveGeneralTitle
                  />
                )}
              </SubCategoryWrapper>
            )}
            {openSettingOption.openConfigSettings && openSettingOption.selectedCategory?.configs?.length === 0 && !subCategoryList?.loading && subCategoryList?.categories?.length === 0 && (
              <NotFoundSource
                content={t('NOT_FOUND_CONFIG', 'Sorry, we couldn\'t find the config.')}
              />
            )}
          </ContentWrapper>
        </div>
        {!subCategoryList?.loading && subCategoryList?.categories?.length > 0 && (
          subCategoryList?.categories.map((category, i) => (
            <div key={i} className='shadow-container'>
              <ContentWrapper className='row'>
                <HeaderTitleContainer style={{ padding: '0 20px' }}>
                  {category && (
                    <Content>
                      <CategoryName>
                        <CategoryNameContainer>
                          <p>{category.name}</p>
                          <InfoWrapper>
                            <IconButton
                              color='primary'
                              onClick={() => setOpenMoreInfo({ open: true, category })}
                            >
                              <InfoCircle />
                            </IconButton>
                          </InfoWrapper>
                        </CategoryNameContainer>
                      </CategoryName>
                      <Description>{category?.description}</Description>
                    </Content>
                  )}
                </HeaderTitleContainer>
                <SubCategoryWrapper >
                  <SettingsList
                    {...props}
                    category={category}
                    onCloseSettingsList={() => (null)}
                    isRemoveGeneralTitle
                  />
                </SubCategoryWrapper>
              </ContentWrapper>
            </div>
          ))
        )}
      </BasicSettingsContainer>
      {openMoreInfo.open && (
        <InfoHelpBox
          open={openMoreInfo.open}
          category={openMoreInfo.category}
          handleOnClose={setOpenMoreInfo}
        />
      )}
    </>
  )
}

export const SettingsMenuOptionDetails = (props) => {
  const isAllSettingPage = location.pathname === '/all-settings'
  const query = new URLSearchParams(useLocation().search)
  const categoryId = query.get('category')
  const subSettingId = isAllSettingPage && !isNaN(Number(categoryId)) && categoryId

  const settingsProps = {
    ...props,
    UIComponent: SettingsMenuOptionDetailsUI,
    subSettingId
  }
  return (
    <SettingsController {...settingsProps} />
  )
}
