import { useConfig, useLanguage } from '../../../../../index'

export const defaultOptionsValues = (keyType = 'key_basic') => {
  const [, t] = useLanguage()
  const [{ configs }] = useConfig()

  const values = [
    {
      id: 'user_information',
      title: t('USER_INFORMATION', 'User Information'),
      pageName: 'standard_settings',
      url: '?category=user_information',
      type: 'key_basic',
      enabled: true
    },
    {
      id: 'checkout_fields',
      title: t('CHECKOUT_FIELDS_BY_ORDER_TYPE', 'Checkout by order type'),
      pageName: 'standard_settings',
      url: '?category=checkout_fields',
      type: 'key_basic',
      enabled: true
    },
    {
      id: 'language_manager',
      title: t('LANGUAGE_MANAGER', 'Language manager'),
      pageName: 'standard_settings',
      url: '?category=language_manager',
      type: 'key_basic',
      enabled: true
    },
    {
      id: 'languages',
      title: t('LANGUAGES_SETTINGS', 'Languages'),
      pageName: 'standard_settings',
      url: '?category=languages',
      type: 'key_pro',
      enabled: true
    },
    {
      id: 'places',
      title: t('COUNTRIES_CITIES', 'Countries/Cities'),
      pageName: 'standard_settings',
      url: '?category=places',
      type: 'key_enterprise',
      enabled: true
    },
    {
      id: 'multi_country',
      title: t('MULTI_COUNTRY_SETTINGS', 'Multi country settings'),
      pageName: 'standard_settings',
      url: '?category=multi_country',
      type: 'key_enterprise',
      enabled: configs?.multicountry?.value
    },
    {
      id: 'sites',
      title: t('CHANNEL_SPECIFIC_SETTINGS', 'Channel-Specific Settings'),
      pageName: 'standard_settings',
      url: '?category=sites',
      type: 'key_enterprise',
      enabled: true
    },
    {
      id: 'developers',
      title: t('DEVELOPERS', 'Developers'),
      pageName: 'standard_settings',
      url: '?category=developers',
      type: 'key_pro',
      enabled: true
    },
    {
      id: 'pages',
      title: t('CMS_HEADING', 'CMS'),
      pageName: 'standard_settings',
      url: '?category=pages',
      type: 'key_general',
      enabled: true
    }
  ]
  const filterValues = values.filter(({ type }) => (type === keyType))
  return filterValues ?? []
}

export const settingPageList = {
  key_basic: 'standard_settings',
  key_operation: 'operation_settings',
  key_plugin: 'plugin_settings',
  key_pro: 'pro_settings',
  key_enterprise: 'enterprise_settings',
  all_settings: 'all_settings',
  key_general: 'general_settings'
}
