import React, { useEffect, useState } from 'react'
import { useConfig, useLanguage, useSession } from '../../../../../../index'
import { CheckSquareFill, Square, StarFill } from 'react-bootstrap-icons'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import {
  ContactAdminButton,
  Container,
  ContentWrapper,
  CreditCardPaymentsContainer,
  Divider,
  PaymethodItem
} from './styles'
import { useProjectState } from '../../../../contexts/ProjectContext'

export const PaymentMethods = (props) => {
  const {
    paymethodsList,
    handleChangePaymethodIds,
    paymethodIds,
    isAddonStripeConnect,
    isProjectProPlan,
    isProjectEnterpricePlan,
    setShowBillingPayment
  } = props

  const [, t] = useLanguage()
  const [{ configs }] = useConfig()
  const [{ user }] = useSession()
  const [projectStatus] = useProjectState()
  const [excludePaymethods, setExcludedPaymethods] = useState([])
  const [creditCardPymethods, setCreditCardPaymethods] = useState([])
  const methodsPay = ['google_pay', 'apple_pay']
  const isAddonMultiCheckout = configs?.addon_multi_business_checkout && configs?.addon_multi_business_checkout?.value
  const hasNoPlan = (!isProjectProPlan && !isProjectEnterpricePlan)
  const showUpgradeToPro = user?.level === 0 && user?.user_system_id && projectStatus?.plan?.upgrade_plan_id

  const handleChangeIds = (id) => {
    let updateIds = []
    if (paymethodIds.includes(id)) updateIds = paymethodIds.filter(paymethodId => paymethodId !== id)
    else updateIds = [...paymethodIds, id]
    handleChangePaymethodIds(updateIds)
  }

  useEffect(() => {
    if (!paymethodsList?.loading && paymethodsList?.paymethods?.length > 0) {
      handleChangeIds(paymethodsList?.paymethods[0].id)
    }
  }, [paymethodsList])

  useEffect(() => {
    const paymethodsNotAllowed = []
    const paymethodsCreditCard = []
    const paymentsCreditCards = [...methodsPay, 'stripe', 'stripe_checkout', 'paypal']
    paymethodsCreditCard.push(...paymentsCreditCards)
    if (!isAddonStripeConnect) {
      const paymentsToHide = ['stripe_connect']
      paymethodsNotAllowed.push(...paymentsToHide)
    }
    if (!isAddonMultiCheckout) {
      const paymentsToHide = ['global_google_pay', 'global_apple_pay']
      paymethodsNotAllowed.push(...paymentsToHide)
    }
    setExcludedPaymethods(paymethodsNotAllowed)
    setCreditCardPaymethods(paymethodsCreditCard)
  }, [])

  return (
    <Container>
      <h2>{t('PAYMENT_METHODS', 'Payment methods')}<sup>*</sup></h2>
      <ContentWrapper>
        {!paymethodsList?.loading && paymethodsList?.paymethods?.filter(paymethod => !creditCardPymethods.includes(paymethod.gateway) && !excludePaymethods.includes(paymethod.gateway)).map((paymethod, idx) => (
          <PaymethodItem key={paymethod?.id} isBorderTop={idx === 0} onClick={() => handleChangeIds(paymethod.id)}>
            {paymethodIds.includes(paymethod?.id) ? <CheckSquareFill className='enabled' /> : <Square />}
            <span>{t(paymethod?.gateway?.toUpperCase(), paymethod?.name)}</span>
          </PaymethodItem>
        ))}
        <Divider />
        <CreditCardPaymentsContainer isBlurried={hasNoPlan}>
          <h2>{t('CREDIT_CARD_PAYMENTS', 'Credit card payments')}</h2>
          {hasNoPlan && (
            <>
              {showUpgradeToPro
                ? (
                  <ButtonBootstrap
                    className='d-flex align-items-center upgrade-to-pro-button'
                    variant={false}
                    onClick={() => setShowBillingPayment((prevState) => ({ ...prevState, open: true, from: 'upgrade_pro' }))}
                  >
                    <StarFill />
                    <span>{t('UPGRADE_TO_PRO', 'Upgrade to Pro')}</span>
                  </ButtonBootstrap>
                  )
                : (
                  <ContactAdminButton>
                    {t('CONTACT_YOUR_ADMIN', 'Contact your admin')}
                  </ContactAdminButton>
                  )}
            </>
          )}
          {!paymethodsList?.loading && paymethodsList?.paymethods?.filter(paymethod => creditCardPymethods.includes(paymethod.gateway) && !excludePaymethods.includes(paymethod.gateway)).map((paymethod, idx) => (
            <PaymethodItem
              key={paymethod?.id}
              isBorderTop={idx === 0}
              hasNoPlan={hasNoPlan}
              onClick={() => !hasNoPlan && handleChangeIds(paymethod.id)}
            >
              {paymethodIds.includes(paymethod?.id) ? <CheckSquareFill className='enabled' /> : <Square />}
              <span>{t(paymethod?.gateway?.toUpperCase(), paymethod?.name)}</span>
            </PaymethodItem>
          ))}
        </CreditCardPaymentsContainer>
      </ContentWrapper>
    </Container>
  )
}
