import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { RewardsPrograms as RewardsProgramsController } from '~ui'

export const RewardsPrograms = (props) => {
  const rewardsProgramsProps = {
    ...props,
    isPointsWallets: true,
    settingsType: 'addon_points_and_levels'
  }

  return (
    <>
      <HelmetTags page='rewards_programs' />
      <RewardsProgramsController {...rewardsProgramsProps} />
    </>
  )
}
