import styled from 'styled-components'

export const ScheduleContainer = styled.div`
  > button {
    margin: 30px 0 20px;
    height: 44px;
  }
`

export const ScheduleInnerContainer = styled.div`
`
