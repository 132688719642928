import React, { useState, useEffect, useContext } from 'react'
import MdExitToApp from '@meronex/icons/md/MdExitToApp'
import BsArrowRightShort from '@meronex/icons/bs/BsArrowRightShort'
import { useForm, Controller } from 'react-hook-form'
import parsePhoneNumber from 'libphonenumber-js'
import HiOutlineMail from '@meronex/icons/hi/HiOutlineMail'
import RiLockPasswordLine from '@meronex/icons/ri/RiLockPasswordLine'
import { Eye, EyeSlash, Phone } from 'react-bootstrap-icons'
import OtpInput from 'react-otp-input'
import { ConfigFileContext } from '../../../contexts/ConfigFileContext'
import {
  LoginForm as LoginFormController,
  ReCaptcha,
  useLanguage,
  useApi,
  useConfig
} from '../../../../../index'
import { useCountdownTimer } from '../../../hooks/useCountdownTimer'
import { Alert, InputPhoneNumber, SpinnerLoader } from '../../Shared'
import { Button, Input } from '../../../styles'
import { useTheme } from 'styled-components'
import { formatSeconds, getOrderingSubdomains } from '../../../utils'

import {
  LoginContainer,
  LoginHeroContainer,
  FormSide,
  FormInput,
  RedirectLink,
  LoginWith,
  TitleFormSide,
  InputWithIcon,
  WrapperPassword,
  TogglePassword,
  ReCAPTCHAWrapper,
  Tabs,
  Tab,
  CountdownTimer,
  OtpWrapper,
  ResendCode,
  PhoneNumberWrapper
} from './styles'

const LoginFormUI = (props) => {
  const {
    useLoginOtpEmail,
    useLoginOptCellphone,
    useLoginByCellphone,
    handleChangeInput,
    handleButtonLoginClick,
    elementLinkToSignup,
    elementLinkToForgotPassword,
    formState,
    loginTab,
    isPopup,
    isReCaptchaEnable,
    handleReCaptcha,
    useProjectDomain,
    checkPhoneCodeState,
    otpType,
    setOtpType,
    handleChangeTab,
    generateOtpCode,
    otpState,
    setOtpState,
    useLoginByEmail,
    handleChangeCredentials,
    credentials,
    setCellphoneStartZero
  } = props
  const [, t] = useLanguage()
  const [ordering, { configurations }] = useApi()
  const [{ configs, loading }] = useConfig()
  const theme = useTheme()
  const { handleSubmit, register, errors, control } = useForm()
  const [configFile, setConfigFile] = useContext(ConfigFileContext)

  const [alertState, setAlertState] = useState({ open: false, content: [] })
  const [reCaptchaVersion, setRecaptchaVersion] = useState({ version: 'v3', siteKey: '' })
  const [submitted, setSubmitted] = useState(false)
  const [passwordSee, setPasswordSee] = useState(false)
  const [loginWithOtpState, setLoginWithOtpState] = useState(false)
  const [willVerifyOtpState, setWillVerifyOtpState] = useState(false)
  const [timer, setTimer] = useState(null)

  const numOtpInputs = loginTab === 'otp' ? 6 : 4
  const otpPlaceholder = [...Array(numOtpInputs)].fill(0).join('')
  const [otpLeftTime, , resetOtpLeftTime] = useCountdownTimer(
    600, !checkPhoneCodeState?.loading && willVerifyOtpState)

  const isOtpEmail = loginTab === 'otp' && otpType === 'email'
  const isOptCellphone = loginTab === 'otp' && otpType === 'cellphone'
  const isOrderingSubdomain = getOrderingSubdomains()
  const whiteLabelSubdomainValidation = (!useProjectDomain || (useProjectDomain && isOrderingSubdomain))

  const onSubmit = () => {
    if (useLoginOptCellphone && loginTab === 'otp' && otpType === 'cellphone' && !credentials?.country_phone_code && !credentials?.cellphone) {
      setAlertState({
        open: true,
        content: [t('PHONE_NUMBER_IS_NOT_VALID', 'Phone number is not valid')]
      })
      return
    }
    setSubmitted(true)
  }

  const hanldeChangeProject = (project) => {
    clearTimeout(timer)
    setSubmitted(false)
    const _timer = setTimeout(function () {
      setConfigFile({ ...configFile, project })
    }, 750)
    setTimer(_timer)
  }

  const handleChangeOtpType = (type) => {
    handleChangeTab('otp')
    setOtpType(type)
  }

  const handleSendOtp = () => {
    if (willVerifyOtpState) {
      setOtpState('')
      resetOtpLeftTime()
      if (loginTab === 'otp') {
        generateOtpCode()
        setWillVerifyOtpState(true)
      }
    }
  }

  const handleChangePhoneNumber = (number, isValid, rawNumber) => {
    let phoneNumberParser = null
    let values = { country_phone_code: '', cellphone: '' }

    if (isValid) {
      phoneNumberParser = parsePhoneNumber(number)
    }
    if (phoneNumberParser) {
      values = {
        country_phone_code: phoneNumberParser.countryCallingCode,
        cellphone: phoneNumberParser.nationalNumber
      }
    }
    setCellphoneStartZero && setCellphoneStartZero(rawNumber?.number && rawNumber?.countryCallingCode ? rawNumber?.number : null)
    handleChangeCredentials(values)
  }

  useEffect(() => {
    if (ordering.project === null || !submitted) return
    if (loginTab === 'otp') {
      generateOtpCode()
      setWillVerifyOtpState(true)
    } else {
      handleButtonLoginClick()
    }
  }, [ordering, submitted])

  useEffect(() => {
    if (!formState.loading && formState.result?.error) {
      if (formState.result?.result?.[0] === 'ERROR_AUTH_VERIFICATION_CODE') {
        setRecaptchaVersion({ version: 'v2', siteKey: configs?.security_recaptcha_site_key?.value })
        setAlertState({
          open: true,
          content: [t('TRY_AGAIN', 'Please try again')]
        })
        setSubmitted(false)
        return
      }
      setAlertState({
        open: true,
        content: formState.result?.result || [t('ERROR')]
      })
      setSubmitted(false)
    }
  }, [formState])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlertState({
        open: true,
        content: Object.values(errors).map((error) => error.message)
      })
    }
  }, [errors])

  const closeAlert = () => {
    setAlertState({
      open: false,
      content: []
    })
    setOtpState('')
  }

  useEffect(() => {
    if (otpState?.length === numOtpInputs) {
      if (loginTab === 'otp') {
        handleButtonLoginClick()
      }
    }
  }, [otpState])

  useEffect(() => {
    if (checkPhoneCodeState?.result?.error) {
      setAlertState({
        open: true,
        content: checkPhoneCodeState?.result?.result || [t('ERROR', 'Error')]
      })
    } else if (checkPhoneCodeState?.result?.result) {
      resetOtpLeftTime()
    }
  }, [checkPhoneCodeState])

  useEffect(() => {
    if (otpLeftTime === 0) {
      setAlertState({
        open: true,
        content: t('TIME_IS_UP_PLEASE_RESEND_CODE', 'Time is up. Please resend code again')
      })
    }
  }, [otpLeftTime])

  useEffect(() => {
    if (configs && Object.keys(configs).length > 0 && configs?.security_recaptcha_auth?.value === '1') {
      if (configs?.security_recaptcha_type?.value === 'v3' &&
        configs?.security_recaptcha_score_v3?.value > 0 &&
        configs?.security_recaptcha_site_key_v3?.value
      ) {
        setRecaptchaVersion({ version: 'v3', siteKey: configs?.security_recaptcha_site_key_v3?.value })
        return
      }
      if (configs?.security_recaptcha_site_key?.value) {
        setRecaptchaVersion({ version: 'v2', siteKey: configs?.security_recaptcha_site_key?.value })
      }
    }
  }, [configs])

  return (
    <>
      {useProjectDomain && loading && !isOrderingSubdomain && (
        <SpinnerLoader />
      )}
      {(((useProjectDomain && !loading) || isOrderingSubdomain) || !useProjectDomain) && (
        <LoginContainer isPopup={isPopup}>
          <LoginHeroContainer bgimage={configs?.dashboard_login_background?.value || theme.images?.general?.loginHero}>
            <img src={configs?.dashboard_logo?.value || theme?.images?.logos?.logotypeInvert} alt='Logo login' />
          </LoginHeroContainer>

          <FormSide isPopup={isPopup}>
            <TitleFormSide>
              <h1>{t('WELCOME', 'Welcome!')}</h1>
              <p>
                {t(
                  'SUBTITLE_ADMIN_LOGIN',
                  'Let’s start to admin your business now'
                )}
              </p>
            </TitleFormSide>

            {ordering?.project && (
              <LoginWith>
                <Tabs>
                  {useLoginByEmail && (
                    <Tab
                      onClick={() => handleChangeTab('email')}
                      active={loginTab === 'email'}
                    >
                      {t('LOGIN_WITH_EMAIL', 'Login with Email')}
                    </Tab>
                  )}
                  {useLoginOtpEmail && (
                    <Tab
                      onClick={() => handleChangeOtpType('email')}
                      active={isOtpEmail}
                    >
                      {t('BY_OTP_EMAIL', 'by Otp Email')}
                    </Tab>
                  )}
                  {useLoginOptCellphone && (
                    <Tab
                      onClick={() => handleChangeOtpType('cellphone')}
                      active={isOptCellphone}
                    >
                      {t('BY_OTP_PHONE', 'by Otp Phone')}
                    </Tab>
                  )}
                  {useLoginByCellphone && (
                    <Tab
                      onClick={() => handleChangeTab('cellphone')}
                      active={loginTab === 'cellphone'}
                    >
                      {t('LOGIN_WITH_PHONE', 'Login with phone')}
                    </Tab>
                  )}
                </Tabs>
              </LoginWith>
            )}

            <FormInput
              noValidate
              isPopup={isPopup}
              onSubmit={handleSubmit(onSubmit)}
            >
              {!willVerifyOtpState && whiteLabelSubdomainValidation && (
                <InputWithIcon>
                  <Controller
                    name='project'
                    control={control}
                    rules={{
                      required: t(
                        'VALIDATION_ERROR_PROJECT_REQUIRED',
                        'Project is required'
                      ).replace('_attribute_', t('PROJECT', 'Project'))
                    }}
                    render={({ onChange, value }) => (
                      <Input
                        type='text'
                        placeholder={t('PROJECT', 'Project')}
                        value={value}
                        onChange={(e) => {
                          const project = e.target.value.replace(/\s/g, '')
                          onChange(project)
                          hanldeChangeProject(project)
                        }}
                        autoComplete='off'
                        autoCapitalize='off'
                      />
                    )}
                    defaultValue=''
                  />
                  <MdExitToApp />
                </InputWithIcon>
              )}

              {!willVerifyOtpState && ((loginTab === 'email') || (loginTab === 'otp' && otpType === 'email')) && (
                <InputWithIcon>
                  <Input
                    type='email'
                    name='email'
                    aria-label='email'
                    placeholder={t('EMAIL', 'Email')}
                    ref={register({
                      required: t(
                        'VALIDATION_ERROR_EMAIL_REQUIRED',
                        'Email is required'
                      ).replace('_attribute_', t('EMAIL', 'Email')),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t(
                          'VALIDATION_ERROR_EMAIL',
                          'Invalid email address'
                        ).replace('_attribute_', t('EMAIL', 'Email'))
                      }
                    })}
                    onChange={(e) => handleChangeInput(e)}
                    autoComplete='off'
                    autoCapitalize='off'
                  />
                  <HiOutlineMail />
                </InputWithIcon>
              )}
              {useLoginByCellphone && loginTab === 'cellphone' && !willVerifyOtpState && (
                <InputWithIcon>
                  <Input
                    type='tel'
                    name='cellphone'
                    aria-label='cellphone'
                    placeholder='Cellphone'
                    ref={register({
                      required: t(
                        'VALIDATION_ERROR_CELLPHONE_REQUIRED',
                        'Cellphone is required'
                      ).replace('_attribute_', t('CELLPHONE', 'Cellphone'))
                    })}
                    onChange={(e) => handleChangeInput(e)}
                    autoComplete='off'
                  />
                  <Phone />
                </InputWithIcon>
              )}
              {!willVerifyOtpState && useLoginOptCellphone && loginTab === 'otp' && otpType === 'cellphone' && (
                <PhoneNumberWrapper>
                  <InputPhoneNumber
                    setValue={handleChangePhoneNumber}
                  />
                </PhoneNumberWrapper>
              )}

              {loginTab !== 'otp' && !willVerifyOtpState && (
                <WrapperPassword>
                  <Input
                    type={!passwordSee ? 'password' : 'text'}
                    name='password'
                    aria-label='password'
                    placeholder={t('PASSWORD', 'Password')}
                    ref={register({
                      required: t(
                        'VALIDATION_ERROR_PASSWORD_REQUIRED',
                        'Password is required'
                      ).replace('_attribute_', t('PASSWORD', 'Password')),
                      minLength: {
                        value: 5,
                        message: t(
                          'VALIDATION_ERROR_MIN_STRING',
                          'The Password must be at least 8 characters.'
                        )
                          .replace('_attribute_', t('PASSWORD', 'Password'))
                          .replace('_min_', 8)
                      }
                    })}
                    onChange={(e) => handleChangeInput(e)}
                    autoComplete='off'
                    autoCapitalize='off'
                  />
                  <RiLockPasswordLine />
                  <TogglePassword onClick={() => setPasswordSee(!passwordSee)}>
                    {!passwordSee ? <Eye /> : <EyeSlash />}
                  </TogglePassword>
                </WrapperPassword>
              )}

              {isReCaptchaEnable && (
                <ReCAPTCHAWrapper>
                  <ReCaptcha handleReCaptcha={handleReCaptcha} reCaptchaVersion={reCaptchaVersion} />
                </ReCAPTCHAWrapper>
              )}

              {(!willVerifyOtpState &&
                <Button
                  borderRadius='8px'
                  color='primary'
                  type='submit'
                  disabled={formState.loading}
                >
                  {formState.loading
                    ? t('LOADING', 'Loading') + '...'
                    : loginWithOtpState || loginTab === 'otp'
                      ? t('GET_VERIFY_CODE', 'Get verify code')
                      : t('LOGIN', 'Login')}
                  <BsArrowRightShort />
                </Button>
              )}
              {(willVerifyOtpState && !checkPhoneCodeState?.loading) && (
                <>
                  <CountdownTimer>
                    <span>{formatSeconds(otpLeftTime)}</span>
                  </CountdownTimer>

                  <OtpWrapper>
                    <OtpInput
                      value={otpState}
                      onChange={otp => setOtpState(otp)}
                      numInputs={numOtpInputs}
                      containerStyle='otp-container'
                      inputStyle='otp-input'
                      placeholder={otpPlaceholder}
                      isInputNum
                      shouldAutoFocus
                      isDisabled={otpLeftTime === 0}
                    />
                  </OtpWrapper>
                  <ResendCode disabled={otpLeftTime > 520} onClick={handleSendOtp}>
                    {t('RESEND_AGAIN', 'Resend again')}?
                  </ResendCode>
                  <Button
                    borderRadius='8px'
                    type='button'
                    color='primary'
                    disabled={formState.loading}
                    onClick={() => {
                      setLoginWithOtpState(false)
                      setWillVerifyOtpState(false)
                    }}
                  >
                    {t('CANCEL', 'Cancel')}
                  </Button>
                </>
              )}

              {loginTab !== 'otp' && (
                <RedirectLink isPopup={isPopup}>
                  <span>{t('FORGOT_YOUR_PASSWORD', 'Forgot your password?')}</span>
                  {elementLinkToForgotPassword}
                </RedirectLink>
              )}
            </FormInput>
            {whiteLabelSubdomainValidation && (
              <>
                <RedirectLink>
                  <span className='or'>{t('OR', 'or')}</span>
                </RedirectLink>

                <Button
                  borderRadius='8px'
                  color='primary'
                  outline
                  bgtransparent
                  onClick={() => window.location.assign(configFile?.billing_url ?? `https://partners.${configurations?.subdomain}.com`)}
                >
                  {t('SIGN_UP_FOR_FREE_VIA_PARTNERS', 'Sign up for free / Login via partners portal')}
                </Button>
              </>
            )}
            {elementLinkToSignup && (
              <RedirectLink register isPopup={isPopup}>
                <span>{t('NEW_ON_PLATFORM', 'New on Ordering?')}</span>
                {elementLinkToSignup}
              </RedirectLink>
            )}
          </FormSide>
          <Alert
            title={t('LOGIN', 'Login')}
            content={alertState.content}
            acceptText={t('ACCEPT', 'Accept')}
            open={alertState.open}
            onClose={() => closeAlert()}
            onAccept={() => closeAlert()}
            closeOnBackdrop={false}
          />
        </LoginContainer>
      )}
    </>
  )
}

export const LoginForm = (props) => {
  const [configFile, setConfigFile] = useContext(ConfigFileContext)
  const loginControllerProps = {
    ...props,
    UIComponent: LoginFormUI,
    allowedLevels: [0, 2, 5, 8],
    configFile,
    setConfigFile
  }
  return <LoginFormController {...loginControllerProps} />
}
