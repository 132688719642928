import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { PurchaseAddons as PurchaseAddonsController } from '~ui'

export const PurchaseAddons = (props) => {
  const [, t] = useLanguage()
  const purchaseAddonsProps = {
    ...props,
    settingsType: 'key_basic',
    allowOptions: [],
    pageURL: 'purchase_addons',
    title: t('PURCHASE_ADDONS', 'Addons'),
    description: t('PURCHASE_ADDONS_DESCRIPTION', 'Purchase your favorite addons')
  }

  return (
    <>
      <HelmetTags page='purchase_addons' />
      <PurchaseAddonsController {...purchaseAddonsProps} />
    </>
  )
}
