import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ViewList,
  Table,
  List as MenuIcon
} from 'react-bootstrap-icons'
import { useLanguage, useConfig, LanguageManager as LanguageManagerController } from '../../../../../index'
import { useInfoShare } from '../../../contexts/InfoShareContext'

import { IconButton } from '../../../styles'
import { Select } from '../../../styles/Select'
import {
  LanguageManagerContainer,
  HeaderContainer,
  HeaderTitleContainer,
  ActionsGroup,
  TranslationManagerContainer,
  ActionGroup,
  ViewIconWrapper,
  ActionGroupWrapper,
  SelectContent,
  LanguageSelectorContainer
} from './styles'
import { SearchBar } from '../../Shared'
import { LanguageTransTable } from '../LanguageTransTable'
import { LanguageTransSpread } from '../LanguageTransSpread'
import { LanguageSelector } from '../../LanguageSelector'

import { removeQueryToUrl, addQueryToUrl } from '../../../utils'
import { productTypes } from './utils'

const LanguageManagerUI = (props) => {
  const {
    translationList,
    searchValue,
    onSearch,
    avoidExpand,
    isShowProductType,
    selectedProductType,
    handleSelectChange
  } = props

  const [{ languageList }, t] = useLanguage()
  const [{ isCollapse }, { handleMenuCollapse }] = useInfoShare()
  const [viewMode, setViewMode] = useState('table')
  const [{ configs }] = useConfig()

  const query = new URLSearchParams(useLocation().search)

  const isProjectProPlan = configs?.plan_pro && configs?.plan_pro?.value
  const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value
  const isAddonBusinessApp = configs?.app_business_x?.value
  const isAddonDriverApp = configs?.app_driver_x?.value
  const isAddonWebDashboard = configs?.web_dashboard_x?.value
  const isHasManyLanguages = languageList?.filter((language) => (language.enabled))?.length > 1
  const productTypesOptions = productTypes(
    t,
    isProjectProPlan,
    isProjectEnterpricePlan,
    {
      app_business_x: isAddonBusinessApp,
      app_driver_x: isAddonDriverApp,
      web_dashboard_x: isAddonWebDashboard
    }
  )

  useEffect(() => {
    if (translationList.loading) return
    const productType = query.get('product_type')
    let _productType = ''
    if (productType && !selectedProductType) {
      _productType = productType
      handleSelectChange(productType)
    } else if (selectedProductType) {
      _productType = selectedProductType
    }
    _productType && addQueryToUrl({ product_type: _productType })
  }, [translationList.loading, selectedProductType])

  useEffect(() => {
    if (!productTypesOptions?.length) return
    if (productTypesOptions?.length === 1) {
      handleSelectChange(productTypesOptions[0].value)
    }
  }, [productTypesOptions])

  return (
    <LanguageManagerContainer allowOverflow={!selectedProductType}>
      <HeaderContainer>
        <HeaderTitleContainer>
          {isCollapse && !avoidExpand && (
            <IconButton
              color='black'
              onClick={() => handleMenuCollapse(false)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <h1>{t('LANGUAGE_MANAGER', 'Language Manager')}</h1>
        </HeaderTitleContainer>
        <ActionsGroup>
          {isShowProductType && (isProjectProPlan || isProjectEnterpricePlan) && isHasManyLanguages && (
            <LanguageSelectorContainer>
              <LanguageSelector />
            </LanguageSelectorContainer>
          )}
          <SearchBar
            lazyLoad
            search={searchValue}
            onSearch={(value) => onSearch(value)}
            placeholder={t('SEARCH', 'Search')}
          />
        </ActionsGroup>
      </HeaderContainer>
      <TranslationManagerContainer>
        {isShowProductType && productTypesOptions?.length > 1 && (
          <SelectContent>
            <Select
              defaultValue={selectedProductType}
              options={productTypesOptions}
              onChange={(typeValue) => handleSelectChange(typeValue)}
              placeholder={t('SELECT_A_PRODUCT', 'Select a product')}
            />
          </SelectContent>
        )}
        {((isShowProductType && !!selectedProductType) || !isShowProductType) && !translationList.loading && (
          <>
            <ActionGroupWrapper>
              <ActionGroup>
                <ViewIconWrapper
                  className='table-mode'
                  active={viewMode === 'table'}
                  onClick={() => setViewMode('table')}
                >
                  <ViewList />
                </ViewIconWrapper>
                <ViewIconWrapper
                  active={viewMode === 'spread'}
                  onClick={() => {
                    setViewMode('spread')
                    removeQueryToUrl(['page', 'pageSize'])
                  }}
                >
                  <Table />
                </ViewIconWrapper>
              </ActionGroup>
            </ActionGroupWrapper>
            {
              viewMode === 'table' && (
                <LanguageTransTable
                  {...props}
                />
              )
            }
            {
              viewMode === 'spread' && (
                <LanguageTransSpread {...props} />
              )
            }
          </>
        )}
      </TranslationManagerContainer>
    </LanguageManagerContainer>
  )
}

export const LanguageManager = (props) => {
  const languageManagerProps = {
    ...props,
    UIComponent: LanguageManagerUI
  }
  return <LanguageManagerController {...languageManagerProps} />
}
