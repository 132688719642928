import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController } from '~ui'

export const CateringSettings = (props) => {
  const [, t] = useLanguage()

  const cateringProps = {
    ...props,
    settingsType: 'addon_catering',
    allowOptions: [],
    pageURL: 'catering',
    title: t('CATERING', 'Catering'),
    description: t('CATERING_DESCRIPTION', 'Catering'),
    hasNotParentId: true
  }

  return (
    <>
      <HelmetTags page='catering' />
      <FilterSettingsController {...cateringProps} />
    </>
  )
}
