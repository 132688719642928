import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController } from '~ui'

export const GiftCardSettings = (props) => {
  const [, t] = useLanguage()

  const giftCardProps = {
    ...props,
    settingsType: 'addon_gift_cards',
    allowOptions: [],
    pageURL: 'gift_cards_settings',
    title: t('GIFT_CARD', 'Gift Card'),
    description: t('GIFT_CARD_DESCRIPTION', 'Gift card settings'),
    hasNotParentId: true,
    configsToHide: ['platform_store_header', 'platform_store_logo', 'platform_store_name']
  }

  return (
    <>
      <HelmetTags page='gift_cards_settings' />
      <FilterSettingsController {...giftCardProps} />
    </>
  )
}
