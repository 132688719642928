import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController } from '~ui'

export const StripeConnectSettings = (props) => {
  const [, t] = useLanguage()

  const stripeConnectSettingsProps = {
    ...props,
    settingsType: 'addon_stripe_connect',
    allowOptions: [],
    pageURL: 'stripe_connect',
    title: t('STRIPE_CONNECT', 'Stripe Connect'),
    description: t('STRIPE_CONNECT_DESCRIPTION', 'Split the card payments received directly between your Stripe account and your stores accounts.You can setup the commissions on each store settings.'),
    hasNotParentId: true
  }

  return (
    <>
      <HelmetTags page='stripe_connect' />
      <FilterSettingsController {...stripeConnectSettingsProps} />
    </>
  )
}
