import React from 'react'
import { Helmet } from 'react-helmet'
import helmetData from '../../helmetdata.json'
import settings from '../../config.json'

export const HelmetTags = (props) => {
  const {
    page
  } = props

  const {
    REACT_APP_OG_TITLE,
    REACT_APP_OG_DESCRIPTION,
    REACT_APP_OG_URL,
    REACT_APP_OG_SITE_NAME,
    REACT_APP_OG_IMAGE
  } = process.env

  const metaTag = page ? helmetData[page] : helmetData.app
  return (
    <Helmet titleTemplate={!page ? '' : `${settings.app_name} - %s`}>
      <title>{props.helmetTitle || metaTag.title}</title>
      <meta name='description' content={props.description || metaTag.description} />
      <meta name='keywords' content={props.keywords || metaTag.keywords} />
      {props.robots
        ? (
        <meta name='robots' content={props.robots} />
          )
        : (
            metaTag.robots && <meta name='robots' content={metaTag.robots} />
          )}
      {props.canonicalUrl
        ? (
        <link rel='canonical' href={props.canonicalUrl} />
          )
        : (
            metaTag.canonicalUrl && <link rel='canonical' href={metaTag.canonicalUrl} />
          )}

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={REACT_APP_OG_TITLE} />
      <meta property="og:description" content={REACT_APP_OG_DESCRIPTION} />
      <meta property="og:url" content={REACT_APP_OG_URL} />
      <meta property="og:site_name" content={REACT_APP_OG_SITE_NAME} />
      <meta property="og:image" content={REACT_APP_OG_IMAGE} />
      <meta property="og:image:type" content="image/png" />
    </Helmet>
  )
}
