import React, { useContext, useEffect, useState, useRef } from 'react'
import { List as MenuIcon } from 'react-bootstrap-icons'
import { Accordion, Button, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router-dom'

import { useLanguage, useSession, AllSettings as AllSettingsController } from '../../../../../index'
import { LearnDropDown } from '../../Shared'
import { useInfoShare } from '../../../contexts/InfoShareContext'
import { SettingsMenuOptionDetails } from '../../Settings'
import { learnOptionsValues } from '../../../utils'

import { settingsMenuOptionsValues } from './utils'

import {
  Container,
  MainContainer,
  HeaderTitleContainer,
  IconButton,
  CategoryName,
  CategoryContainer,
  MenuContainer,
  MenuContent,
  SidebarContent,
  SkeletonWrapper
} from './styles'

const AllSettingsUI = (props) => {
  const {
    isProjectProPlan,
    isProjectEnterpricePlan,
    basicCategoryList,
    proCategoryList,
    enterpriseCategoryList,
    pluginsCategoryList,
    loadingCategories
  } = props

  const [, t] = useLanguage()
  const [sessionState] = useSession()
  const location = useLocation()
  const sidebarSettingContainer = useRef(null)
  const [{ isCollapse }, { handleMenuCollapse }] = useInfoShare()

  const [allSettings, setAllSettings] = useState([])
  const query = new URLSearchParams(location.search)
  const categoryId = query.get('category')

  const settingsMenuOptions = settingsMenuOptionsValues({
    location,
    categoryId,
    isProjectProPlan,
    isProjectEnterpricePlan,
    basicCategoryList,
    proCategoryList,
    enterpriseCategoryList,
    pluginsCategoryList,
    t
  })

  const learnOptions = learnOptionsValues(
    [
      'settings_standard',
      'settings_cms',
      'settings_pro'
    ],
    t
  )

  useEffect(() => {
    setAllSettings([...basicCategoryList, ...proCategoryList, ...enterpriseCategoryList, ...pluginsCategoryList])
  }, [basicCategoryList, proCategoryList, enterpriseCategoryList, pluginsCategoryList])

  useEffect(() => {
    if (isCollapse) return
    handleMenuCollapse(true)
  }, [])

  return (
    <>
      <MainContainer>
        <HeaderTitleContainer>
          {isCollapse && (
            <IconButton
              color='black'
              onClick={() => handleMenuCollapse(false)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <CategoryName>
            <p>{t('SETTINGS', 'Settings')}</p>
            <LearnDropDown
              learnOptions={learnOptions}
            />
          </CategoryName>
        </HeaderTitleContainer>
        <Container>
          {!(loadingCategories || sessionState.loading) && !!basicCategoryList.length && (
            <SidebarContent ref={sidebarSettingContainer}>
              <Accordion defaultActiveKey={settingsMenuOptions.defaultActiveKey}>
                {settingsMenuOptions.items.filter(({ enabled }) => enabled).map(item => (
                  <MenuContainer key={item.eventKey}>
                    <ContextAwareToggle
                      eventKey={item.eventKey}
                      active={item.active}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={item.eventKey}>
                      <MenuContent>{item.content}</MenuContent>
                    </Accordion.Collapse>
                  </MenuContainer>
                ))}
              </Accordion>
            </SidebarContent>
          )}
          {!(loadingCategories || sessionState.loading) && !!allSettings.length && (
            <CategoryContainer>
              <SettingsMenuOptionDetails
                defaultCategoryList={allSettings}
                handleUpdateAllSettings={setAllSettings}
                sidebarSettingContainer={sidebarSettingContainer}
                isHideSideMenu
            />
            </CategoryContainer>
          )}

          {(loadingCategories || sessionState.loading) && (
            <div style={{ display: 'flex', width: '100%' }}>
              <SidebarContent>
                <SkeletonWrapper style={{ width: '25%', paddingLeft: 10 }}>
                  {[...Array(2)].map((i, index) => (
                    <div key={index}>
                      <Skeleton height={50} width={'23vw'} />
                      <Skeleton height={30} width={'20vw'} />
                      <Skeleton height={30} width={'20vw'} />
                      <Skeleton height={30} width={'20vw'} />
                      <Skeleton height={30} width={'20vw'} />
                    </div>
                  ))}
                </SkeletonWrapper>
              </SidebarContent>
              <CategoryContainer style={{ width: '70%' }}>
                <SkeletonWrapper style={{ width: '100%', paddingRight: 10 }}>
                  {[...Array(2)].map((i, index) => (
                    <div key={index}>
                      <Skeleton height={60} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                    </div>
                  ))}
                </SkeletonWrapper>
              </CategoryContainer>
            </div>
          )}
        </Container>
      </MainContainer>
    </>
  )
}

const ContextAwareToggle = ({ children, eventKey, callback, page, handleGoToPage, active }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  const handleButtonClick = () => {
    if (page) {
      handleGoToPage({ page })
    }
    decoratedOnClick()
  }
  return (
    <Button
      variant={active
        ? 'primary'
        : isCurrentEventKey && 'light'}
      onClick={handleButtonClick}
    >
      {children}
    </Button>
  )
}

export const AllSettings = (props) => {
  const allSettingsProps = {
    ...props,
    UIComponent: AllSettingsUI
  }

  return (
    <AllSettingsController {...allSettingsProps} />
  )
}
