import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'

import { AllSettings as SettingsController } from '~ui'

export const SettingsAllInOne = (props) => {
  return (
    <>
      <HelmetTags page='all_settings' />
      <SettingsController {...props} />
    </>
  )
}
