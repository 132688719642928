export const CODES = [
  { countryCode: 'DZ', phoneCode: '213', countryName: 'Algeria' },
  { countryCode: 'AD', phoneCode: '376', countryName: 'Andorra' },
  { countryCode: 'AO', phoneCode: '244', countryName: 'Angola' },
  { countryCode: 'AI', phoneCode: '1264', countryName: 'Anguilla' },
  { countryCode: 'AG', phoneCode: '1268', countryName: 'Antigua & Barbuda' },
  { countryCode: 'AR', phoneCode: '54', countryName: 'Argentina' },
  { countryCode: 'AM', phoneCode: '374', countryName: 'Armenia' },
  { countryCode: 'AW', phoneCode: '297', countryName: 'Aruba' },
  { countryCode: 'AU', phoneCode: '61', countryName: 'Australia' },
  { countryCode: 'AT', phoneCode: '43', countryName: 'Austria' },
  { countryCode: 'AZ', phoneCode: '994', countryName: 'Azerbaijan' },
  { countryCode: 'BS', phoneCode: '1242', countryName: 'Bahamas' },
  { countryCode: 'BH', phoneCode: '973', countryName: 'Bahrain' },
  { countryCode: 'BD', phoneCode: '880', countryName: 'Bangladesh' },
  { countryCode: 'BB', phoneCode: '1246', countryName: 'Barbados' },
  { countryCode: 'BY', phoneCode: '375', countryName: 'Belarus' },
  { countryCode: 'BE', phoneCode: '32', countryName: 'Belgium' },
  { countryCode: 'BZ', phoneCode: '501', countryName: 'Belize' },
  { countryCode: 'BJ', phoneCode: '229', countryName: 'Benin' },
  { countryCode: 'BM', phoneCode: '1441', countryName: 'Bermuda' },
  { countryCode: 'BT', phoneCode: '975', countryName: 'Bhutan' },
  { countryCode: 'BO', phoneCode: '591', countryName: 'Bolivia' },
  { countryCode: 'BA', phoneCode: '387', countryName: 'Bosnia Herzegovina' },
  { countryCode: 'BW', phoneCode: '267', countryName: 'Botswana' },
  { countryCode: 'BR', phoneCode: '55', countryName: 'Brazil' },
  { countryCode: 'BN', phoneCode: '673', countryName: 'Brunei' },
  { countryCode: 'BG', phoneCode: '359', countryName: 'Bulgaria' },
  { countryCode: 'BF', phoneCode: '226', countryName: 'Burkina Faso' },
  { countryCode: 'BI', phoneCode: '257', countryName: 'Burundi' },
  { countryCode: 'KH', phoneCode: '855', countryName: 'Cambodia' },
  { countryCode: 'CM', phoneCode: '237', countryName: 'Cameroon' },
  { countryCode: 'CA', phoneCode: '1', countryName: 'Canada' },
  { countryCode: 'CV', phoneCode: '238', countryName: 'Cape Verde Islands' },
  { countryCode: 'KY', phoneCode: '1345', countryName: 'Cayman Islands' },
  { countryCode: 'CF', phoneCode: '236', countryName: 'Central African Republic' },
  { countryCode: 'CL', phoneCode: '56', countryName: 'Chile' },
  { countryCode: 'CN', phoneCode: '86', countryName: 'China' },
  { countryCode: 'CO', phoneCode: '57', countryName: 'Colombia' },
  { countryCode: 'KM', phoneCode: '269', countryName: 'Comoros' },
  { countryCode: 'CG', phoneCode: '242', countryName: 'Congo' },
  { countryCode: 'CK', phoneCode: '682', countryName: 'Cook Islands' },
  { countryCode: 'CR', phoneCode: '506', countryName: 'Costa Rica' },
  { countryCode: 'HR', phoneCode: '385', countryName: 'Croatia' },
  { countryCode: 'CU', phoneCode: '53', countryName: 'Cuba' },
  { countryCode: 'CY', phoneCode: '90392', countryName: 'Cyprus North' },
  { countryCode: 'CY', phoneCode: '357', countryName: 'Cyprus South' },
  { countryCode: 'CZ', phoneCode: '42', countryName: 'Czech Republic' },
  { countryCode: 'DK', phoneCode: '45', countryName: 'Denmark' },
  { countryCode: 'DJ', phoneCode: '253', countryName: 'Djibouti' },
  { countryCode: 'DM', phoneCode: '1809', countryName: 'Dominica' },
  { countryCode: 'DO', phoneCode: '1809', countryName: 'Dominican Republic' },
  { countryCode: 'EC', phoneCode: '593', countryName: 'Ecuador' },
  { countryCode: 'EG', phoneCode: '20', countryName: 'Egypt' },
  { countryCode: 'SV', phoneCode: '503', countryName: 'El Salvador' },
  { countryCode: 'GQ', phoneCode: '240', countryName: 'Equatorial Guinea' },
  { countryCode: 'ER', phoneCode: '291', countryName: 'Eritrea' },
  { countryCode: 'EE', phoneCode: '372', countryName: 'Estonia' },
  { countryCode: 'ET', phoneCode: '251', countryName: 'Ethiopia' },
  { countryCode: 'FK', phoneCode: '500', countryName: 'Falkland Islands' },
  { countryCode: 'FO', phoneCode: '298', countryName: 'Faroe Islands' },
  { countryCode: 'FJ', phoneCode: '679', countryName: 'Fiji' },
  { countryCode: 'FI', phoneCode: '358', countryName: 'Finland' },
  { countryCode: 'FR', phoneCode: '33', countryName: 'France' },
  { countryCode: 'GF', phoneCode: '594', countryName: 'French Guiana' },
  { countryCode: 'PF', phoneCode: '689', countryName: 'French Polynesia' },
  { countryCode: 'GA', phoneCode: '241', countryName: 'Gabon' },
  { countryCode: 'GM', phoneCode: '220', countryName: 'Gambia' },
  { countryCode: 'GE', phoneCode: '7880', countryName: 'Georgia' },
  { countryCode: 'DE', phoneCode: '49', countryName: 'Germany' },
  { countryCode: 'GH', phoneCode: '233', countryName: 'Ghana' },
  { countryCode: 'GI', phoneCode: '350', countryName: 'Gibraltar' },
  { countryCode: 'GR', phoneCode: '30', countryName: 'Greece' },
  { countryCode: 'GL', phoneCode: '299', countryName: 'Greenland' },
  { countryCode: 'GD', phoneCode: '1473', countryName: 'Grenada' },
  { countryCode: 'GP', phoneCode: '590', countryName: 'Guadeloupe' },
  { countryCode: 'GU', phoneCode: '671', countryName: 'Guam' },
  { countryCode: 'GT', phoneCode: '502', countryName: 'Guatemala' },
  { countryCode: 'GN', phoneCode: '224', countryName: 'Guinea' },
  { countryCode: 'GW', phoneCode: '245', countryName: 'Guinea - Bissau' },
  { countryCode: 'GY', phoneCode: '592', countryName: 'Guyana' },
  { countryCode: 'HT', phoneCode: '509', countryName: 'Haiti' },
  { countryCode: 'HN', phoneCode: '504', countryName: 'Honduras' },
  { countryCode: 'HK', phoneCode: '852', countryName: 'Hong Kong' },
  { countryCode: 'HU', phoneCode: '36', countryName: 'Hungary' },
  { countryCode: 'IS', phoneCode: '354', countryName: 'Iceland' },
  { countryCode: 'IN', phoneCode: '91', countryName: 'India' },
  { countryCode: 'ID', phoneCode: '62', countryName: 'Indonesia' },
  { countryCode: 'IR', phoneCode: '98', countryName: 'Iran' },
  { countryCode: 'IQ', phoneCode: '964', countryName: 'Iraq' },
  { countryCode: 'IE', phoneCode: '353', countryName: 'Ireland' },
  { countryCode: 'IL', phoneCode: '972', countryName: 'Israel' },
  { countryCode: 'IT', phoneCode: '39', countryName: 'Italy' },
  { countryCode: 'JM', phoneCode: '1876', countryName: 'Jamaica' },
  { countryCode: 'JP', phoneCode: '81', countryName: 'Japan' },
  { countryCode: 'JO', phoneCode: '962', countryName: 'Jordan' },
  { countryCode: 'KZ', phoneCode: '7', countryName: 'Kazakhst' },
  { countryCode: 'KE', phoneCode: '254', countryName: 'Kenya' },
  { countryCode: 'KI', phoneCode: '686', countryName: 'Kiribati' },
  { countryCode: 'KP', phoneCode: '850', countryName: 'Korea North' },
  { countryCode: 'KR', phoneCode: '82', countryName: 'Korea South' },
  { countryCode: 'KW', phoneCode: '965', countryName: 'Kuwait' },
  { countryCode: 'KG', phoneCode: '996', countryName: 'Kyrgyzstan' },
  { countryCode: 'LA', phoneCode: '856', countryName: 'Laos' },
  { countryCode: 'LV', phoneCode: '371', countryName: 'Latvia' },
  { countryCode: 'LB', phoneCode: '961', countryName: 'Lebanon' },
  { countryCode: 'LS', phoneCode: '266', countryName: 'Lesotho' },
  { countryCode: 'LR', phoneCode: '231', countryName: 'Liberia' },
  { countryCode: 'LY', phoneCode: '218', countryName: 'Libya' },
  { countryCode: 'LI', phoneCode: '417', countryName: 'Liechtenstein' },
  { countryCode: 'LT', phoneCode: '370', countryName: 'Lithuania' },
  { countryCode: 'LU', phoneCode: '352', countryName: 'Luxembourg' },
  { countryCode: 'MO', phoneCode: '853', countryName: 'Macao' },
  { countryCode: 'MK', phoneCode: '389', countryName: 'Macedonia' },
  { countryCode: 'MG', phoneCode: '261', countryName: 'Madagascar' },
  { countryCode: 'MW', phoneCode: '265', countryName: 'Malawi' },
  { countryCode: 'MY', phoneCode: '60', countryName: 'Malaysia' },
  { countryCode: 'MV', phoneCode: '960', countryName: 'Maldives' },
  { countryCode: 'ML', phoneCode: '223', countryName: 'Mali' },
  { countryCode: 'MT', phoneCode: '356', countryName: 'Malta' },
  { countryCode: 'MH', phoneCode: '692', countryName: 'Marshall Islands' },
  { countryCode: 'MQ', phoneCode: '596', countryName: 'Martinique' },
  { countryCode: 'MR', phoneCode: '222', countryName: 'Mauritania' },
  { countryCode: 'MU', phoneCode: '230', countryName: 'Mauritius' },
  { countryCode: 'YT', phoneCode: '269', countryName: 'Mayotte' },
  { countryCode: 'MX', phoneCode: '52', countryName: 'Mexico' },
  { countryCode: 'FM', phoneCode: '691', countryName: 'Micronesia' },
  { countryCode: 'MD', phoneCode: '373', countryName: 'Moldova' },
  { countryCode: 'MC', phoneCode: '377', countryName: 'Monaco' },
  { countryCode: 'MN', phoneCode: '976', countryName: 'Mongolia' },
  { countryCode: 'MS', phoneCode: '1664', countryName: 'Montserrat' },
  { countryCode: 'MA', phoneCode: '212', countryName: 'Morocco' },
  { countryCode: 'MZ', phoneCode: '258', countryName: 'Mozambique' },
  { countryCode: 'MN', phoneCode: '95', countryName: 'Myanmar' },
  { countryCode: 'NA', phoneCode: '264', countryName: 'Namibia' },
  { countryCode: 'NR', phoneCode: '674', countryName: 'Nauru' },
  { countryCode: 'NP', phoneCode: '977', countryName: 'Nepal' },
  { countryCode: 'NL', phoneCode: '31', countryName: 'Netherlands' },
  { countryCode: 'NC', phoneCode: '687', countryName: 'New Caledonia' },
  { countryCode: 'NZ', phoneCode: '64', countryName: 'New Zealand' },
  { countryCode: 'NI', phoneCode: '505', countryName: 'Nicaragua' },
  { countryCode: 'NE', phoneCode: '227', countryName: 'Niger' },
  { countryCode: 'NG', phoneCode: '234', countryName: 'Nigeria' },
  { countryCode: 'NU', phoneCode: '683', countryName: 'Niue' },
  { countryCode: 'NF', phoneCode: '672', countryName: 'Norfolk Islands' },
  { countryCode: 'NP', phoneCode: '670', countryName: 'Northern Marianas' },
  { countryCode: 'NO', phoneCode: '47', countryName: 'Norway' },
  { countryCode: 'OM', phoneCode: '968', countryName: 'Oman' },
  { countryCode: 'PW', phoneCode: '680', countryName: 'Palau' },
  { countryCode: 'PA', phoneCode: '507', countryName: 'Panama' },
  { countryCode: 'PG', phoneCode: '675', countryName: 'Papua New Guinea' },
  { countryCode: 'PY', phoneCode: '595', countryName: 'Paraguay' },
  { countryCode: 'PE', phoneCode: '51', countryName: 'Peru' },
  { countryCode: 'PH', phoneCode: '63', countryName: 'Philippines' },
  { countryCode: 'PL', phoneCode: '48', countryName: 'Poland' },
  { countryCode: 'PT', phoneCode: '351', countryName: 'Portugal' },
  { countryCode: 'PR', phoneCode: '1787', countryName: 'Puerto Rico' },
  { countryCode: 'QA', phoneCode: '974', countryName: 'Qatar' },
  { countryCode: 'RE', phoneCode: '262', countryName: 'Reunion' },
  { countryCode: 'RO', phoneCode: '40', countryName: 'Romania' },
  { countryCode: 'RU', phoneCode: '7', countryName: 'Russ' },
  { countryCode: 'RW', phoneCode: '250', countryName: 'Rwanda' },
  { countryCode: 'SM', phoneCode: '378', countryName: 'San Marino' },
  { countryCode: 'ST', phoneCode: '239', countryName: 'Sao Tome & Principe' },
  { countryCode: 'SA', phoneCode: '966', countryName: 'Saudi Arabia' },
  { countryCode: 'SN', phoneCode: '221', countryName: 'Senegal' },
  { countryCode: 'CS', phoneCode: '381', countryName: 'Serbia' },
  { countryCode: 'SC', phoneCode: '248', countryName: 'Seychelles' },
  { countryCode: 'SL', phoneCode: '232', countryName: 'Sierra Leone' },
  { countryCode: 'SG', phoneCode: '65', countryName: 'Singapore' },
  { countryCode: 'SK', phoneCode: '421', countryName: 'Slovak Republic' },
  { countryCode: 'SI', phoneCode: '386', countryName: 'Slovenia' },
  { countryCode: 'SB', phoneCode: '677', countryName: 'Solomon Islands' },
  { countryCode: 'SO', phoneCode: '252', countryName: 'Somalia' },
  { countryCode: 'ZA', phoneCode: '27', countryName: 'South Africa' },
  { countryCode: 'ES', phoneCode: '34', countryName: 'Spain' },
  { countryCode: 'LK', phoneCode: '94', countryName: 'Sri Lanka' },
  { countryCode: 'SH', phoneCode: '290', countryName: 'St. Helena' },
  { countryCode: 'KN', phoneCode: '1869', countryName: 'St. Kitts' },
  { countryCode: 'SC', phoneCode: '1758', countryName: 'St. Lucia' },
  { countryCode: 'SD', phoneCode: '249', countryName: 'Sudan' },
  { countryCode: 'SR', phoneCode: '597', countryName: 'Suriname' },
  { countryCode: 'SX', phoneCode: '721', countryName: 'Sint Maarten' },
  { countryCode: 'SZ', phoneCode: '268', countryName: 'Swaziland' },
  { countryCode: 'SE', phoneCode: '46', countryName: 'Sweden' },
  { countryCode: 'CH', phoneCode: '41', countryName: 'Switzerland' },
  { countryCode: 'SI', phoneCode: '963', countryName: 'Syria' },
  { countryCode: 'TW', phoneCode: '886', countryName: 'Taiwan' },
  { countryCode: 'TJ', phoneCode: '7', countryName: 'Tajikst' },
  { countryCode: 'TH', phoneCode: '66', countryName: 'Thailand' },
  { countryCode: 'TG', phoneCode: '228', countryName: 'Togo' },
  { countryCode: 'TO', phoneCode: '676', countryName: 'Tonga' },
  { countryCode: 'TT', phoneCode: '1868', countryName: 'Trinidad & Tobago' },
  { countryCode: 'TN', phoneCode: '216', countryName: 'Tunisia' },
  { countryCode: 'TR', phoneCode: '90', countryName: 'Turkey' },
  { countryCode: 'TM', phoneCode: '7', countryName: 'Turkmenist' },
  { countryCode: 'TM', phoneCode: '993', countryName: 'Turkmenistan' },
  { countryCode: 'TC', phoneCode: '1649', countryName: 'Turks & Caicos Islands' },
  { countryCode: 'TV', phoneCode: '688', countryName: 'Tuvalu' },
  { countryCode: 'UG', phoneCode: '256', countryName: 'Uganda' },
  { countryCode: 'GB', phoneCode: '44', countryName: 'UK' },
  { countryCode: 'UA', phoneCode: '380', countryName: 'Ukraine' },
  { countryCode: 'AE', phoneCode: '971', countryName: 'United Arab Emirates' },
  { countryCode: 'UY', phoneCode: '598', countryName: 'Uruguay' },
  { countryCode: 'US', phoneCode: '1', countryName: 'USA' },
  { countryCode: 'UZ', phoneCode: '7', countryName: 'Uzbekist' },
  { countryCode: 'VU', phoneCode: '678', countryName: 'Vanuatu' },
  { countryCode: 'VA', phoneCode: '379', countryName: 'Vatican City' },
  { countryCode: 'VE', phoneCode: '58', countryName: 'Venezuela' },
  { countryCode: 'VN', phoneCode: '84', countryName: 'Vietnam' },
  { countryCode: 'VG', phoneCode: '84', countryName: 'Virgin Islands - British' },
  { countryCode: 'VI', phoneCode: '84', countryName: 'Virgin Islands - US' },
  { countryCode: 'WF', phoneCode: '681', countryName: 'Wallis & Futuna' },
  { countryCode: 'YE', phoneCode: '969', countryName: 'Yemen (North' },
  { countryCode: 'YE', phoneCode: '967', countryName: 'Yemen (South' },
  { countryCode: 'ZM', phoneCode: '260', countryName: 'Zambia' },
  { countryCode: 'ZW', phoneCode: '263', countryName: 'Zimbabwe' }
]
