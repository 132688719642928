import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useEvent, Settings as SettingsController } from '../../../../../index'

import { firstLetterCapital } from '../../../utils'
import { defaultOptionsValues, settingPageList } from './utils'

import {
  SubMenu
} from './styles'

const SettingsMenuOptionsUI = (props) => {
  const {
    categoryList,
    settingsType,
    defaultCategoryList
  } = props

  const location = useLocation()

  const [settingsMenuOptions, setSettingsMenuOptions] = useState(null)

  const [events] = useEvent()

  const defaultOptionsBasic = defaultOptionsValues()
  const defaultOptionsPro = defaultOptionsValues('key_pro')
  const defaultOptionsEnterprise = defaultOptionsValues('key_enterprise')
  const defaultOptionsGeneral = defaultOptionsValues('key_general')

  const onSettingsRedirect = ({ category }) => {
    if (!category) {
      return events.emit('go_to_page', { page: settingPageList[defaultCategoryList ? 'all_settings' : settingsType], replace: true })
    }
    if (category) {
      events.emit('go_to_page', {
        page: settingPageList[defaultCategoryList ? 'all_settings' : settingsType],
        search: `?category=${category}`,
        replace: true
      })
    }
  }

  useEffect(() => {
    if (categoryList.loading) return
    const _settingsMenuOptions = []
    const formatOptions = categoryList?.categories.length && categoryList?.categories?.map((category) => {
      return {
        id: category.id,
        title: category.name,
        pageName: settingPageList[settingsType],
        url: `?category=${category.id}`,
        enabled: true
      }
    })
    if (formatOptions) {
      _settingsMenuOptions.push(...formatOptions)
    }
    if (settingsType === 'key_basic') {
      _settingsMenuOptions.push(...defaultOptionsBasic)
    }
    if (settingsType === 'key_pro') {
      _settingsMenuOptions.push(...defaultOptionsPro)
    }
    if (settingsType === 'key_enterprise') {
      _settingsMenuOptions.push(...defaultOptionsEnterprise)
    }
    if (settingsType === 'key_general') {
      _settingsMenuOptions.push(...defaultOptionsGeneral)
    }
    setSettingsMenuOptions(_settingsMenuOptions)
  }, [categoryList.loading])

  return (
    <>
      {settingsMenuOptions && settingsMenuOptions.map(item => (
        item.enabled && (
          <SubMenu
            key={item.id}
            active={location.search.includes(item?.url)}
            onClick={() => onSettingsRedirect({ category: item.id })}
          >
            {firstLetterCapital(item.title)}
          </SubMenu>
        )
      ))}
    </>
  )
}

export const SettingsMenuOptions = (props) => {
  const settingsProps = {
    ...props,
    UIComponent: SettingsMenuOptionsUI
  }
  return (
    <SettingsController {...settingsProps} />
  )
}
