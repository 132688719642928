import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  useSession,
  useOrder,
  useLanguage,
  useConfig,
  // GoogleTagManager,
  useEvent,
  useApi
} from '~components'

import {
  NotNetworkConnectivity,
  useOnlineStatus,
  useWindowSize,
  useProjectState,
  SidebarMenu,
  Layout,
  Banners,
  SpinnerLoader,
  ProtectedRoute,
  QueryLogin,
  Modal,
  BillingPaymentOptions
} from '~ui'

import { PageNotFound } from './pages/PageNotFound'
import { Home } from './pages/Home'
import { SettingsAllInOne } from './pages/SettingsAllInOne'
import { Login } from './pages/Login'
import { ForgotPassword } from './pages/ForgotPassword'
import { OrdersList } from './pages/OrdersList'
import { DeliveriesManager } from './pages/DeliveriesManager'
import { DriversList } from './pages/DriversList'
import { AllInOne } from './pages/AllInOne'
import { CustomersList } from './pages/CustomersList'
import { BusinessOwnersList } from './pages/BusinessOwnersList'
import { CallcenterAgentsList } from './pages/CallcenterAgentsList'
import { ManagersList } from './pages/ManagersList'
import { BusinessesList } from './pages/BusinessesList'
import { BrandManager } from './pages/BrandManager'
import { BasicSettings } from './pages/BasicSettings'
import { StandardSettings } from './pages/StandardSettings'
import { OperationSettings } from './pages/OperationSettings'
import { ProSettings } from './pages/ProSettings'
import { BusinessProductsList } from './pages/BusinessProductsList'
import { BusinessAnalytics } from './pages/BusinessAnalytics'
import { MessagesList } from './pages/MessagesList'
import { DriverAnalytics } from './pages/DriverAnalytics'
import { InvoiceManager } from './pages/InvoiceManager'
import { Cms } from './pages/Cms'
import { IntegrationsList } from './pages/IntegrationsList'
import { LanguageManager } from './pages/LanguageManager'
import { PlacesList } from './pages/PlacesList'
import { ReviewsList } from './pages/ReviewsList'
import { DeliveryDriversList } from './pages/DeliveryDriversList'
import { DriversManagersList } from './pages/DriversManagersList'
import { DriversCompaniesList } from './pages/DriversCompaniesList'
import { DriversGroupsList } from './pages/DriversGroupsList'
import { Support } from './pages/Support'
import { AdvancedReports } from './pages/AdvancedReports'
import { EnterprisePromotionList } from './pages/EnterprisePromotionList'
import { RewardsPrograms } from './pages/RewardsPrograms'
import { CashWalletsRefunds } from './pages/CashWalletsRefunds'
import { CateringSettings } from './pages/CateringSettings'
import { GiftCardSettings } from './pages/GiftCardSettings'
import { MultiCheckoutSettings } from './pages/MultiCheckoutSettings'
import { StripeConnectSettings } from './pages/StripeConnectSettings'
import { CampaignListing } from './pages/CampaignListing'
import { OpenCarts } from './pages/OpenCarts'
import { RecoveryActionListing } from './pages/RecoveryActionListing'
import { OrderingProducts } from './pages/OrderingProducts'
import { Professionals } from './pages/Professionals'
import { Appointments } from './pages/Appointments'
import { GiftCardsList } from './pages/GiftCardsList'
import { ResetPassword } from './pages/ResetPassword'
import { Profile } from './pages/Profile'
import { OrderingWebsite } from './pages/OrderingWebsite'
import { Dashboard } from './pages/Dashboard'
import { ProductsBuilderList } from './pages/ProductsList'
import { BusinessAdd } from './pages/BusinessAdd'
import { AdBannersList } from './pages/AdBannersList'
import { BusinessDevicesList } from './pages/BusinessDevicesList'

import { ScrollToTop } from './components/ScrollToTop'
import { ListenPageChanges } from './components/ListenPageChanges'
import { HelmetTags } from './components/HelmetTags'
import { IntercomWrapper } from './components/IntercomWrapper'
import { PluginSettings } from './pages/PluginSettings'
import settings from './config.json'
import { SettingsLogs } from './pages/SettingsLogs'
import { EnterpriseIntegrationsSettings } from './pages/EnterpriseIntegrationsSettings'
import { PurchaseAddons } from './pages/PurchaseAddons'
import { DriversTimeDisplay } from './pages/DriverTimeDisplay'

export const App = () => {
  const history = useHistory()
  const [events] = useEvent()
  const [{ auth, loading, user, token }] = useSession()
  const [orderStatus] = useOrder()
  const [projectStatus] = useProjectState()
  const [{ configs, loading: configLoading }] = useConfig()
  const [ordering] = useApi()
  const [loaded, setLoaded] = useState(false)
  const [layoutPT, setLayoutPT] = useState(0)
  const [isShowBillingPayment, setShowBillingPayment] = useState({ open: false, from: '', showInformation: false })

  const [oneSignalState, setOneSignalState] = useState({
    notification_app: settings.notification_app
  })

  const [, t] = useLanguage()
  const onlineStatus = useOnlineStatus()

  const readOnlyBusinessOwner = user?.readOnlyBusinessOwner
  const readOnlyDeliveryManager = user?.readOnlyDeliveryManager
  const readOnlyAdmin = user?.readOnlyAdmin
  const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value
  const isPastDue = projectStatus.project?.current_status === 'past_due'
  const showBanner = auth && isPastDue && user?.user_system_id && !projectStatus.loading && !configLoading && !loading && !isShowBillingPayment?.open

  const { height } = useWindowSize()
  const { search } = useLocation()
  let queryProject
  let queryToken
  if (search) {
    const query = new URLSearchParams(search)
    queryProject = query.get('project')
    queryToken = query.get('token')
  }

  useEffect(() => {
    if (!loaded && !orderStatus.loading) {
      setLoaded(true)
    }
  }, [orderStatus])

  useEffect(() => {
    if (!loading) {
      setLoaded(!auth)
    }
  }, [loading])

  useEffect(() => {
    if (height) {
      const vh = height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }, [height])

  const oneSignalSetup = () => {
    const oneSignalId = configs?.onesignal_default_enabled?.value === '1' ? configs?.onesignal_default_dashboardweb_id?.value : configs?.onesignal_dashboardweb_id?.value
    if (!oneSignalId) {
      setOneSignalState({
        notification_app: settings.notification_app
      })
      return
    }
    const OneSignal = window.OneSignal || []
    const initConfig = {
      appId: oneSignalId,
      // allowLocalhostAsSecureOrigin: true,
      notificationClickHandlerAction: 'navigate'
    }

    OneSignal.push(function () {
      if (!OneSignal._initCalled) {
        OneSignal.init(initConfig)
      }

      const onNotificationClicked = function (data) {
        if (data?.data?.order_uuid) {
          history.push(`/orders?id=${data?.data?.order_uuid}`)
        }
      }
      const handler = function (data) {
        onNotificationClicked(data)
        OneSignal.addListenerForNotificationOpened(handler)
      }
      OneSignal.addListenerForNotificationOpened(handler)

      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          OneSignal.getUserId((userId) => {
            const data = {
              ...oneSignalState,
              notification_token: userId
            }
            setOneSignalState(data)
          })
        }
      })

      OneSignal.getUserId((userId) => {
        const data = {
          ...oneSignalState,
          notification_token: userId
        }
        setOneSignalState(data)
      })
    })
  }

  useEffect(() => {
    if (configLoading) return
    oneSignalSetup()
  }, [configLoading, events])

  useEffect(() => {
    if (!showBanner) return
    setShowBillingPayment((prevState) => ({ ...prevState, open: true, from: 'past_due' }))
  }, [configLoading, loading, projectStatus.loading])

  useEffect(() => {
    const updateNotificationToken = async () => {
      try {
        const body = {
          token: oneSignalState.notification_token,
          user_id: user?.id,
          app: settings.notification_app
        }
        await fetch(`${ordering.root}/users/${user?.id}/notification_tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(body)
        })
      } catch (err) {
      }
    }
    if (auth && !oneSignalState.subscribed && oneSignalState.notification_token && loaded) {
      updateNotificationToken()
    }
  }, [auth, oneSignalState.subscribed, oneSignalState.notification_token, loaded])

  return (
    <>
      <ListenPageChanges />
      {
        !loaded && (
          <SpinnerLoader />
        )
      }
      {
        loaded && (
          <IntercomWrapper>
            <NotNetworkConnectivity />
            {/* <GoogleTagManager tagId={configs?.google_tag_manager?.value ?? settings?.google_tag_manager} /> */}
            {showBanner && (
              <Banners
                setLayoutPT={setLayoutPT}
                setShowBillingPayment={setShowBillingPayment}
              />
            )}
            <Layout pt={showBanner && `${layoutPT}px`}>
              {auth && (
                <SidebarMenu billingUrl={settings?.billing_url} setShowBillingPayment={setShowBillingPayment} />
              )}
              {onlineStatus && (
                <ScrollToTop>
                  <HelmetTags />
                  <Switch>
                    <Route exact path='/'>
                      {
                        auth
                          ? ((user?.level !== 5 && user?.level !== 8) && !(readOnlyAdmin || readOnlyDeliveryManager || readOnlyBusinessOwner))
                              ? <Redirect to='/home' />
                              : user?.level === 8 ? <Redirect to='/profile' /> : <Redirect to='/orders' />
                          : (queryProject && queryToken)
                              ? <QueryLogin project={queryProject} token={queryToken} />
                              : <Redirect to='/login' />
                      }
                    </Route>

                    <Route exact path='/login'>
                      {
                        !auth
                          ? (
                            <Login
                              useLoginByEmail
                              elementLinkToForgotPassword={<Link to='/password/forgot'>{t('RESET_PASSWORD', 'Reset password')}</Link>}
                              notificationState={oneSignalState}
                              setNotificationState={setOneSignalState}
                            />
                            )
                          : (
                            <Redirect to='/home' />
                            )
                      }
                    </Route>

                    <Route exact path='/password/forgot'>
                      {
                        !auth
                          ? (
                            <ForgotPassword
                              elementLinkToLogin={<Link to='/login'>{t('LOGIN', 'Login')}</Link>}
                            />
                            )
                          : <Redirect to='/home' />
                      }
                    </Route>

                    <Route exact path='/password/reset'>
                      {auth
                        ? (
                          <Redirect to='/' />
                          )
                        : (
                          <ResetPassword
                            elementLinkToLogin={<Link to='/login'>{t('LOGIN', 'Login')}</Link>}
                          />
                          )}
                    </Route>

                    <Route exact path='/reset_password'>
                      {auth
                        ? (
                          <Redirect to='/' />
                          )
                        : (
                          <ResetPassword
                            elementLinkToLogin={<Link to='/login'>{t('LOGIN', 'Login')}</Link>}
                          />
                          )}
                    </Route>

                    <Route exact path='/home'>
                      {
                        auth
                          ? ((user?.level !== 5 && user?.level !== 8) && !(readOnlyAdmin || readOnlyDeliveryManager || readOnlyBusinessOwner))
                              ? <Home />
                              : user?.level === 8 ? <Redirect to='/profile' /> : <Redirect to='/orders' />
                          : <Redirect to='/login' />
                      }
                    </Route>

                    <ProtectedRoute path='/orders' allowedLevels={[0, 2, 5]}>
                      <OrdersList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/deliveries' allowedLevels={[0, 2, 5]}>
                      <DeliveriesManager />
                    </ProtectedRoute>
                    <ProtectedRoute path='/drivers-dashboard' allowedLevels={[0, 5]}>
                      <DriversList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/all-in-one' allowedLevels={[0, 2, 5]}>
                      <AllInOne />
                    </ProtectedRoute>
                    <ProtectedRoute path='/appointments' allowedLevels={[0, 2]}>
                      <Appointments />
                    </ProtectedRoute>

                    <ProtectedRoute path='/messages' allowedLevels={[0, 2]}>
                      <MessagesList />
                    </ProtectedRoute>

                    <ProtectedRoute path='/stores/list' allowedLevels={[0, 2]}>
                      <BusinessesList setShowBillingPayment={setShowBillingPayment} />
                    </ProtectedRoute>
                    <ProtectedRoute path='/stores/products/:store?' allowedLevels={[0, 2]}>
                      <BusinessProductsList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/stores/brand' allowedLevels={[0]}>
                      <BrandManager />
                    </ProtectedRoute>
                    <ProtectedRoute path='/stores/devices' allowedLevels={[0]}>
                      <BusinessDevicesList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/stores/add' allowedLevels={[0, 2]}>
                      <BusinessAdd setShowBillingPayment={setShowBillingPayment} />
                    </ProtectedRoute>

                    <ProtectedRoute path='/users/customers' allowedLevels={[0]}>
                      <CustomersList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/users/business-owners' allowedLevels={[0]}>
                      <BusinessOwnersList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/users/callcenter-agents' allowedLevels={[0]}>
                      <CallcenterAgentsList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/users/admins' allowedLevels={[0]}>
                      <ManagersList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/users/professionals' allowedLevels={[0, 2, 5]}>
                      <Professionals />
                    </ProtectedRoute>

                    <ProtectedRoute path='/intelligence/business' allowedLevels={[0, 2]}>
                      <BusinessAnalytics />
                    </ProtectedRoute>
                    <ProtectedRoute path='/intelligence/drivers' allowedLevels={[0]}>
                      <DriverAnalytics />
                    </ProtectedRoute>
                    <ProtectedRoute path='/intelligence/invoice' allowedLevels={[0]}>
                      <InvoiceManager />
                    </ProtectedRoute>
                    <ProtectedRoute path='/intelligence/reviews' allowedLevels={[0]}>
                      <ReviewsList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/intelligence/reports' allowedLevels={[0]}>
                      <AdvancedReports />
                    </ProtectedRoute>

                    <ProtectedRoute path='/delivery/drivers-list' allowedLevels={[0, 5, 2]}>
                      <DeliveryDriversList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/delivery/drivers-managers' allowedLevels={[0]}>
                      <DriversManagersList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/delivery/drivers-companies' allowedLevels={[0]}>
                      <DriversCompaniesList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/delivery/drivers-groups' allowedLevels={[0, 5]}>
                      <DriversGroupsList />
                    </ProtectedRoute>
                    {(isProjectEnterpricePlan || configLoading) && (
                      <ProtectedRoute path='/delivery/drivers-time-display' allowedLevels={[0, 5]}>
                        <DriversTimeDisplay />
                      </ProtectedRoute>
                    )}

                    <ProtectedRoute path='/marketing/promotions-enterprise' allowedLevels={[0]}>
                      <EnterprisePromotionList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/marketing/campaign' allowedLevels={[0]}>
                      <CampaignListing />
                    </ProtectedRoute>
                    <ProtectedRoute path='/marketing/ad-banners' allowedLevels={[0]}>
                      <AdBannersList />
                    </ProtectedRoute>
                    {(configs?.addon_points_and_levels?.value || configLoading) && (
                      <ProtectedRoute path='/loyalty/rewards-programs' allowedLevels={[0]}>
                        <RewardsPrograms />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_gift_cards?.value || configLoading) && (
                      <ProtectedRoute path='/gift-cards' allowedLevels={[0]}>
                        <GiftCardsList />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_cash_wallet_and_refunds?.value || configLoading) && (
                      <ProtectedRoute path='/loyalty/wallets-refunds' allowedLevels={[0]}>
                        <CashWalletsRefunds />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_gift_cards?.value || configLoading) && (
                      <ProtectedRoute path='/gift-cards-settings' allowedLevels={[0]}>
                        <GiftCardSettings />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_catering?.value || configLoading) && (
                      <ProtectedRoute path='/catering' allowedLevels={[0]}>
                        <CateringSettings />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_multi_business_checkout?.value || configLoading) && (
                      <ProtectedRoute path='/multi-business-checkout' allowedLevels={[0]}>
                        <MultiCheckoutSettings />
                      </ProtectedRoute>
                    )}
                    {(configs?.addon_stripe_connect?.value || configLoading) && (
                      <ProtectedRoute path='/stripe-connect' allowedLevels={[0]}>
                        <StripeConnectSettings />
                      </ProtectedRoute>
                    )}

                    <ProtectedRoute path='/cart-recovery/open-carts' allowedLevels={[0]}>
                      <OpenCarts />
                    </ProtectedRoute>
                    <ProtectedRoute path='/cart-recovery/recovery-actions' allowedLevels={[0]}>
                      <RecoveryActionListing />
                    </ProtectedRoute>

                    <ProtectedRoute path='/my-products/ordering-website' allowedLevels={[0]}>
                      <OrderingWebsite />
                    </ProtectedRoute>

                    {(configs?.web_dashboard_x?.value || configLoading) && (
                      <ProtectedRoute path='/my-products/dashboard' allowedLevels={[0]}>
                        <Dashboard />
                      </ProtectedRoute>
                    )}

                    <ProtectedRoute path='/my-products' allowedLevels={[0]}>
                      <ProductsBuilderList />
                    </ProtectedRoute>

                    <ProtectedRoute path='/settings/basic' allowedLevels={[0]}>
                      <BasicSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/standard' allowedLevels={[0]}>
                      <StandardSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/all-settings' allowedLevels={[0]}>
                      <SettingsAllInOne />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/operation' allowedLevels={[0]}>
                      <OperationSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/pro' allowedLevels={[0]}>
                      <ProSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/plugin' allowedLevels={[0]}>
                      <PluginSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/pages' allowedLevels={[0]}>
                      <Cms />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/places' allowedLevels={[0]}>
                      <PlacesList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/integrations' allowedLevels={[0]}>
                      <IntegrationsList />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/language' allowedLevels={[0]}>
                      <LanguageManager />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/logs' allowedLevels={[0]}>
                      <SettingsLogs />
                    </ProtectedRoute>
                    <ProtectedRoute path='/settings/enterprise' allowedLevels={[0]}>
                      <EnterpriseIntegrationsSettings />
                    </ProtectedRoute>
                    <ProtectedRoute path='/purchase/addons' allowedLevels={[0]}>
                      <PurchaseAddons />
                    </ProtectedRoute>
                    <ProtectedRoute path='/support' allowedLevels={[0]}>
                      <Support />
                    </ProtectedRoute>

                    <ProtectedRoute path='/ordering-products' allowedLevels={[0]}>
                      <OrderingProducts />
                    </ProtectedRoute>

                    <ProtectedRoute path='/profile' allowedLevels={[0, 2, 5, 8]}>
                      <Profile />
                    </ProtectedRoute>

                    <Route path='*'>
                      <PageNotFound />
                    </Route>
                  </Switch>
                </ScrollToTop>
              )}
            </Layout>
            {isShowBillingPayment?.open && !projectStatus.loading && (
              <Modal
                width='55%'
                padding='30px'
                title={t('PAYMENTS', 'Payments')}
                open={isShowBillingPayment?.open}
                onClose={() => setShowBillingPayment((prevState) => ({ ...prevState, open: false, from: '' }))}
              >
                <BillingPaymentOptions
                  isUpgradeToPro={isShowBillingPayment?.from === 'upgrade_pro'}
                  setShowBillingPayment={setShowBillingPayment}
                  upgradeProPlanId={[projectStatus?.plan?.upgrade_plan_id]}
                  showPlanInformation={isShowBillingPayment?.showInformation}
                />
              </Modal>
            )}
          </IntercomWrapper>
        )
      }
    </>
  )
}
