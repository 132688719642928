import React from 'react'

import { Star, StarFill, Stars, Gear, FileEarmarkMedicalFill } from 'react-bootstrap-icons'
import { SettingsMenuOptions } from '../../Settings'

export const settingsMenuOptionsValues = (props) => {
  const {
    location,
    categoryId,
    isProjectProPlan,
    isProjectEnterpricePlan,
    basicCategoryList,
    proCategoryList,
    enterpriseCategoryList,
    pluginsCategoryList,
    t
  } = props

  return {
    defaultActiveKey: '11',
    items: [
      {
        eventKey: '11',
        active: location.pathname === '/all-settings' && (
          defaultSettings('basic').includes(location?.search) ||
          basicCategoryList.find(({ id }) => id === parseInt(categoryId))
        ),
        icon: <Star />,
        label: t('SETTINGS_STANDARD', 'Standard'),
        content: <SettingsMenuOptions settingsType="key_basic" defaultCategoryList={basicCategoryList} />,
        enabled: !!basicCategoryList.length
      },
      {
        eventKey: '12',
        active: location.pathname === '/all-settings' && (
          defaultSettings('pro').includes(location?.search) ||
          proCategoryList.find(({ id }) => id === parseInt(categoryId))
        ),
        icon: <StarFill />,
        label: t('SETTINGS_PRO', 'Pro'),
        content: <SettingsMenuOptions settingsType="key_pro" defaultCategoryList={proCategoryList} />,
        enabled: (isProjectProPlan || isProjectEnterpricePlan) && !!proCategoryList.length
      },
      {
        eventKey: '13',
        active: location.pathname === '/all-settings' && (
          defaultSettings('enterprise').includes(location?.search.split('&')[0]) ||
          enterpriseCategoryList.find(({ id }) => id === parseInt(categoryId))
        ),
        icon: <Stars />,
        label: t('SETTINGS_ENTERPRISE', 'Enterprise'),
        content: <SettingsMenuOptions settingsType="key_enterprise" defaultCategoryList={enterpriseCategoryList} />,
        enabled: isProjectEnterpricePlan && !!enterpriseCategoryList.length
      },
      {
        eventKey: '14',
        active: location.pathname === '/all-settings' && (
          pluginsCategoryList.find(({ id }) => id === parseInt(categoryId))
        ),
        icon: <Gear />,
        label: t('PLUGIN_SETTINGS', 'Plugin settings'),
        content: <SettingsMenuOptions settingsType="key_plugin" defaultCategoryList={pluginsCategoryList} />,
        enabled: !!pluginsCategoryList.length
      },
      {
        eventKey: '15',
        active: location.pathname === '/all-settings' && (defaultSettings('general').includes(location?.search)),
        icon: <FileEarmarkMedicalFill />,
        label: t('CMS_SETTINGS', 'CMS'),
        content: <SettingsMenuOptions settingsType="key_general" defaultCategoryList={[]} />,
        enabled: true
      }
    ]
  }
}

export const defaultSettings = (type = 'basic') => {
  const valuesBasic = [
    '?category=user_information',
    '?category=checkout_fields',
    '?category=language_manager'
  ]
  const valuesPro = [
    '?category=languages',
    '?category=developers'
  ]
  const valuesEnterprise = [
    '?category=multi_country',
    '?category=places',
    '?category=sites'
  ]
  const valuesGeneral = [
    '?category=pages'
  ]
  const valuesToReturn = {
    key_basic: valuesBasic,
    key_pro: valuesPro,
    key_enterprise: valuesEnterprise,
    key_general: valuesGeneral
  }
  return valuesToReturn[`key_${type}`]
}

export const settingsSubMenusValues = (t) => {
  return [
    {
      id: 5,
      title: t('CMS_HEADING', 'CMS'),
      pageName: 'pages',
      url: '/settings/pages',
      enabled: true
    },
    {
      id: 6,
      title: t('DEVELOPERS', 'Developers'),
      pageName: 'integrations',
      url: '/settings/integrations',
      enabled: false
    },
    {
      id: 7,
      title: t('COUNTRIES_CITIES', 'Countries/Cities'),
      pageName: 'places',
      url: '/settings/places',
      enabled: false
    },
    {
      id: 8,
      title: t('LANGUAGE_MANAGER', 'Language manager'),
      pageName: 'language',
      url: '/settings/language',
      enabled: false
    },
    {
      id: 9,
      title: t('SETTINGS_LOGS', 'Settings logs'),
      pageName: 'logs',
      url: '/settings/logs',
      enabled: false
    }
  ]
}
