import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController } from '~ui'

export const MultiCheckoutSettings = (props) => {
  const [, t] = useLanguage()

  const multiCheckoutSettingsProps = {
    ...props,
    settingsType: 'addon_multi_business_checkout',
    allowOptions: [],
    pageURL: 'multi_business_checkout',
    title: t('MULTI_BUSINESS_CHECKOUT', 'Multi-business checkout'),
    description: t('MULTI_BUSINESS_CHECKOUT_DESCRIPTION', 'Allow your customers to checkout multiple carts at the same time.'),
    hasNotParentId: true
  }

  return (
    <>
      <HelmetTags page='multi_business_checkout' />
      <FilterSettingsController {...multiCheckoutSettingsProps} />
    </>
  )
}
