import React from 'react'
import { useConfig, useLanguage } from '../../../../../index'
import { DragScroll } from '../../Shared'

import {
  UserDetailsMenuContainer,
  Tab
} from './styles'

export const UserDetailsMenu = (props) => {
  const {
    currentMenuSelected,
    handleChangeMenu,
    isDriverMenu
  } = props

  const [, t] = useLanguage()
  const [configState] = useConfig()

  const isProjectEnterpricePlan = configState?.configs?.plan_enterprise && configState?.configs?.plan_enterprise?.value

  const menuItems = isDriverMenu
    ? [
        { key: 'profile', content: t('PROFILE', 'Profile'), enabled: true },
        { key: 'driver_group', content: t('DRIVER_GROUP', 'Driver group'), enabled: true },
        { key: 'saved_places', content: t('SAVED_PLACES', 'Saved places'), enabled: true },
        { key: 'schedule', content: t('SCHEDULE', 'Schedule'), enabled: true },
        { key: 'logs', content: t('LOGS', 'Logs'), enabled: isProjectEnterpricePlan },
        { key: 'advanced_logs', content: t('ADVANCED_LOGS', 'Advanced logs') },
        { key: 'push_tokens', content: t('PUSH_TOKENS', 'Push tokens'), enabled: isProjectEnterpricePlan },
        { key: 'metafields', content: t('METAFIELDS', 'Metafields'), enabled: false },
        { key: 'personalization', content: t('PERSONALIZATION', 'Personalization'), enabled: false }
      ]
    : [
        { key: 'profile', content: t('PROFILE', 'Profile'), enabled: true },
        { key: 'saved_places', content: t('SAVED_PLACES', 'Saved places'), enabled: true },
        { key: 'metafields', content: t('METAFIELDS', 'Metafields'), enabled: false },
        { key: 'personalization', content: t('PERSONALIZATION', 'Personalization'), enabled: false }
      ]

  return (
    <UserDetailsMenuContainer>
      <DragScroll>
        {menuItems.map(menu => (
          menu.enabled && (
            <Tab
              key={menu.key}
              active={menu.key === currentMenuSelected}
              onClick={() => handleChangeMenu(menu.key)}
            >
              {menu.content}
            </Tab>
          )
        ))}
      </DragScroll>
    </UserDetailsMenuContainer>
  )
}
