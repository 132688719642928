import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  useLanguage,
  useConfig,
  BusinessPaymethods as BusinessPaymentMethodsController,
  useSession
} from '../../../../../index'
import RiCheckboxBlankLine from '@meronex/icons/ri/RiCheckboxBlankLine'
import RiCheckboxFill from '@meronex/icons/ri/RiCheckboxFill'
import { ChevronRight, StarFill } from 'react-bootstrap-icons'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Modal, SearchBar, Alert } from '../../Shared'
import { Button } from '../../../styles'
import { PaymentOptionStripeDirect } from '../PaymentOptionStripeDirect'
import { PaymentOption } from '../PaymentOption'
import { PaymethodOptionPaypalExpress } from '../PaymethodOptionPaypalExpress'
import { PaymethodOptionStripeConnect } from '../PaymethodOptionStripeConnect'
import { PaymentOptionPaypal } from '../PaymentOptionPaypal'
import { PaymentOptionSquare } from '../PaymentOptionSquare'
import { PaymentOptionMethods } from '../PaymentOptionMethods'
import { PaymentOptionCredomatic } from '../PaymentOptionCredomatic'
import { BusinessWalletsList } from '../BusinessWalletsList'
import { PaymentOptionGlobal } from '../PaymentOptionGlobal'
import { addQueryToUrl, removeQueryToUrl, capitalize } from '../../../utils'

import {
  MainContainer,
  PaymentMethodsContainer,
  PaymethodListWrapper,
  PaymethodOptionContainer,
  PaymethodOption,
  PaymethodName,
  SearchBarWrapper,
  ButtonGroup,
  Divider,
  CreditCardPaymentsContainer,
  ContactAdminButton
} from './styles'
import { getUtils } from './utils'
import { useProjectState } from '../../../contexts/ProjectContext'

const BusinessPaymentMethodsUI = (props) => {
  const {
    business,
    businessPaymethodsState,
    paymethodsList,
    handleClickPayment,
    handleSelectAllPaymethods,
    // handleSelectNonePaymethods,
    handleDeleteBusinessPaymethodOption,
    setIsExtendExtraOpen,
    actionState,
    changesState,
    sitesState,
    deviceState,
    stripeConnectData,
    handleChangeBusinessPaymentState,
    handleChangeStripeConnectData,
    cleanChangesState,
    handleChangeSandbox,
    handleChangeInput,
    handleSaveClick,
    handleStripeConnect,
    handleChangeStripeInput,
    handleStripeSave,
    isSuccessDeleted,
    setIsSuccessDeleted,
    handleSuccessPaymethodUpdate,
    handleSuccessUpdate,
    isTutorialMode,
    handleTutorialContinue,
    setShowBillingPayment
  } = props

  const query = new URLSearchParams(useLocation().search)
  const [, t] = useLanguage()
  const [{ configs }] = useConfig()
  const [{ user }] = useSession()
  const { width } = useWindowSize()
  const { orderTypesValues, paymentsGlobalConfigs, checkBusinessPaymethodConfigs } = getUtils()
  const [projectStatus] = useProjectState()
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBusinessPaymethod, setSelectedBusinessPaymethod] = useState(null)
  const [selectedPaymethodGateway, setSelectedPaymethodGateway] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [alertState, setAlertState] = useState({ open: false, content: [] })
  const [isOpenWalletDetails, setIsOpenWalletDetails] = useState(false)
  const isWalletCashEnabled = configs?.wallet_enabled?.value === '1' && configs?.addon_points_and_levels?.value
  const [excludePaymethods, setExcludedPaymethods] = useState([])
  const [creditCardPymethods, setCreditCardPaymethods] = useState([])
  const isProjectProPlan = configs?.plan_pro && configs?.plan_pro?.value
  const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value
  const isAddonStripeConnect = configs?.addon_stripe_connect && configs?.addon_stripe_connect?.value
  const isAddonMultiCheckout = configs?.addon_multi_business_checkout && configs?.addon_multi_business_checkout?.value
  const isAddonCatering = configs?.addon_catering?.value
  const isAddonReservation = configs?.addon_reservation?.value
  const hasNoPlan = (!isProjectProPlan && !isProjectEnterpricePlan)
  const showUpgradeToPro = user?.level === 0 && user?.user_system_id && projectStatus?.plan?.upgrade_plan_id
  const orderTypes = orderTypesValues({
    isProjectProPlan,
    isProjectEnterpricePlan,
    isAddonCatering,
    isAddonReservation
  })

  const methodsPay = ['google_pay', 'apple_pay']
  const globalPayments = ['global_google_pay', 'global_apple_pay', 'stripe']
  const noKeyPayments = ['cash', 'card_delivery']
  const stripeDirectMethods = ['stripe_checkout']

  const closeAlert = () => {
    setAlertState({
      open: false,
      content: []
    })
  }

  const isCheckEnableState = (id) => {
    const found = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === id)
    let valid = false
    if (found) {
      valid = found.enabled
    }
    return valid
  }

  const isCheckFoundBusinessPaymethod = (id) => {
    const found = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === id)
    return found
  }

  const handleOpenEdit = (e, paymethodId, paymethodGateway, isInitialRender) => {
    const inValid = e?.target?.closest('.paymethod-checkbox')
    if (inValid) return true
    if (!isTutorialMode && isCheckFoundBusinessPaymethod(paymethodId)) {
      setSelectedPaymethodGateway(paymethodGateway)
      const businessPaymethod = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === paymethodId)
      setSelectedBusinessPaymethod(businessPaymethod)
      setIsEdit(true)
      setIsExtendExtraOpen(true)
    } else if (!isTutorialMode && !isCheckFoundBusinessPaymethod(paymethodId)) {
      handleClickCheckBox(paymethodId)
      setSelectedPaymethodGateway(paymethodGateway)
      const businessPaymethod = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === paymethodId)
      setSelectedBusinessPaymethod(businessPaymethod)
      setIsEdit(true)
      setIsExtendExtraOpen(true)
    }
    if (!isInitialRender) {
      addQueryToUrl({ paymethod: paymethodId })
    }
  }

  const handleClickCheckBox = (id) => {
    let i = 0
    let currentPaymentData = null
    paymethodsList.paymethods.forEach(paymethod => {
      if (isCheckEnableState(paymethod.id)) {
        i += 1
      }
      if (paymethod.id === id) {
        currentPaymentData = paymethod
      }
    })

    if (isCheckEnableState(id) && i < 2) {
      setAlertState({
        open: true,
        content: t('VALIDATION_ERROR_MIN_ARRAY', 'The _attribute_ must have at least _min_ items.').replace('_attribute_', t('PAYMETHODS', 'Payment methods')).replace('_min_', t('ONE', 'one'))
      })
      return
    }
    const businessPaymethod = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === id)

    if (
      globalPayments.includes(currentPaymentData?.gateway) &&
      paymentsGlobalConfigs[currentPaymentData?.gateway] &&
      user?.level !== 0
    ) {
      setAlertState({
        open: true,
        content: t('VALIDATION_ERROR_STRIPE_PAYMENT', 'Please contact administrator')
      })
      return
    }

    if (!noKeyPayments.includes(currentPaymentData?.gateway) && businessPaymethod &&
      !isCheckEnableState(id) && ((!globalPayments.includes(currentPaymentData?.gateway) && checkBusinessPaymethodConfigs(businessPaymethod)) ||
      (globalPayments.includes(currentPaymentData?.gateway) && paymentsGlobalConfigs[currentPaymentData?.gateway]))
    ) {
      setSelectedPaymethodGateway(currentPaymentData?.gateway)
      setSelectedBusinessPaymethod(businessPaymethod)
      setIsEdit(true)
      setIsExtendExtraOpen(true)
      addQueryToUrl({ paymethod: id })
      setAlertState({
        open: true,
        content: t('VALIDATION_ERROR_EMPTY_DATA', 'Please fill all the data to activate the payment method (sandbox mode is opcional)')
      })
      return
    }

    handleClickPayment && handleClickPayment(id, (payment) => {
      setSelectedPaymethodGateway(currentPaymentData?.gateway)
      setSelectedBusinessPaymethod(payment)
      setIsEdit(true)
      setIsExtendExtraOpen(true)
      addQueryToUrl({ paymethod: id })
    })
  }

  const handleCloseEdit = () => {
    setIsExtendExtraOpen(false)
    setIsEdit(false)
    setSelectedBusinessPaymethod(null)
    setIsSuccessDeleted(false)
    removeQueryToUrl(['paymethod'])
  }

  useEffect(() => {
    if (!isSuccessDeleted) return
    handleCloseEdit()
  }, [isSuccessDeleted])

  useEffect(() => {
    if (!selectedBusinessPaymethod) return
    const updatedPaymethod = businessPaymethodsState.paymethods.find(paymethod => paymethod.paymethod_id === selectedBusinessPaymethod.paymethod_id)
    setSelectedBusinessPaymethod(updatedPaymethod)
  }, [businessPaymethodsState?.paymethods, selectedBusinessPaymethod])

  useEffect(() => {
    if (paymethodsList.loading || businessPaymethodsState.loading) return
    const paymethodId = query.get('paymethod')
    if (paymethodId) {
      const initPaymethod = paymethodsList.paymethods.find(paymethod => paymethod.id === Number(paymethodId))
      if (initPaymethod) {
        handleOpenEdit(null, initPaymethod.id, initPaymethod.gateway, true)
      }
    }
  }, [paymethodsList.loading, businessPaymethodsState.loading])

  useEffect(() => {
    const paymethodsNotAllowed = []
    const paymethodsCreditCard = []
    const paymentsCreditCards = [...methodsPay, 'stripe', 'stripe_checkout', 'paypal']
    paymethodsCreditCard.push(...paymentsCreditCards)
    if (!isAddonStripeConnect) {
      const paymentsToHide = ['stripe_connect']
      paymethodsNotAllowed.push(...paymentsToHide)
    }
    if (!isAddonMultiCheckout) {
      const paymentsToHide = ['global_google_pay', 'global_apple_pay']
      paymethodsNotAllowed.push(...paymentsToHide)
    }
    setExcludedPaymethods(paymethodsNotAllowed)
    setCreditCardPaymethods(paymethodsCreditCard)
  }, [])

  return (
    <MainContainer>
      <PaymentMethodsContainer isOpenWalletDetails={isOpenWalletDetails}>
        <h1>{t('PAYMETHODS', 'Payment methods')}</h1>
        <SearchBarWrapper>
          <SearchBar
            placeholder={t('SEARCH', 'Search')}
            isCustomLayout
            search={searchValue}
            onSearch={val => setSearchValue(val)}
          />
        </SearchBarWrapper>
        <ButtonGroup>
          <Button
            color='secundaryDark'
            onClick={() => handleSelectAllPaymethods()}
            disabled={(paymethodsList.loading || businessPaymethodsState.loading)}
          >
            {t('SELECT_ALL', 'Select all')}
          </Button>
          {/* <Button
            color='secundaryDark'
            onClick={() => handleSelectNonePaymethods()}
            disabled={(paymethodsList.loading || businessPaymethodsState.loading)}
          >
            {t('SELECT_NONE', 'Select none')}
          </Button> */}
        </ButtonGroup>

        {(paymethodsList.loading || businessPaymethodsState.loading)
          ? (
              [...Array(10).keys()].map(i => (
              <PaymethodOptionContainer key={i}>
                <PaymethodOption>
                  <Skeleton width={15} height={15} />
                  <PaymethodName>
                    <Skeleton width={120} height={24} />
                  </PaymethodName>
                </PaymethodOption>
              </PaymethodOptionContainer>
              ))
            )
          : (
            <PaymethodListWrapper>
              {paymethodsList.paymethods.filter(paymethod => (!creditCardPymethods.includes(paymethod.gateway) && !excludePaymethods.includes(paymethod.gateway)) && paymethod?.name?.toLowerCase().includes(searchValue?.toLowerCase())).map(paymethod => (
                <PaymethodOptionContainer
                  key={paymethod.id}
                  onClick={e => handleOpenEdit(e, paymethod.id, paymethod.gateway)}
                  active={paymethod.id === selectedBusinessPaymethod?.paymethod_id}
                  disabled={isTutorialMode || !(isCheckFoundBusinessPaymethod(paymethod.id))}
                >
                  <PaymethodOption
                    className='paymethod-checkbox'
                    onClick={() => handleClickCheckBox(paymethod.id)}
                  >
                    {isCheckEnableState(paymethod.id)
                      ? (
                        <RiCheckboxFill className='fill' />
                        )
                      : (
                        <RiCheckboxBlankLine />
                        )}
                  </PaymethodOption>
                  <PaymethodName>{t(paymethod?.gateway?.toUpperCase(), paymethod?.name)}</PaymethodName>
                  {!isTutorialMode && isCheckFoundBusinessPaymethod(paymethod.id) && (
                    <ChevronRight />
                  )}
                </PaymethodOptionContainer>
              ))}
              <Divider />
              <CreditCardPaymentsContainer isBlurried={hasNoPlan}>
                <h2>{t('CREDIT_CARD_PAYMENTS', 'Credit card payments')}</h2>
                {hasNoPlan && (
                  <>
                    {showUpgradeToPro
                      ? (
                        <ButtonBootstrap
                          className='d-flex align-items-center upgrade-to-pro-button'
                          variant={false}
                          onClick={() => setShowBillingPayment((prevState) => ({ ...prevState, open: true, from: 'upgrade_pro' }))}
                        >
                          <StarFill />
                          <span>{t('UPGRADE_TO_PRO', 'Upgrade to Pro')}</span>
                        </ButtonBootstrap>
                        )
                      : (
                        <ContactAdminButton>
                          {t('CONTACT_YOUR_ADMIN', 'Contact your admin')}
                        </ContactAdminButton>
                        )}
                  </>
                )}
                <div>
                  {paymethodsList.paymethods.filter(paymethod => creditCardPymethods.includes(paymethod.gateway) && paymethod?.name?.toLowerCase().includes(searchValue?.toLowerCase())).map(paymethod => (
                    <PaymethodOptionContainer
                      key={paymethod.id}
                      onClick={e => !hasNoPlan && handleOpenEdit(e, paymethod.id, paymethod.gateway)}
                      active={!hasNoPlan && paymethod.id === selectedBusinessPaymethod?.paymethod_id}
                      disabled={isTutorialMode || !(isCheckFoundBusinessPaymethod(paymethod.id))}
                      hasNoPlan={hasNoPlan}
                    >
                      <PaymethodOption
                        className='paymethod-checkbox'
                        onClick={() => !hasNoPlan && handleClickCheckBox(paymethod.id)}
                      >
                        {isCheckEnableState(paymethod.id)
                          ? (
                            <RiCheckboxFill className='fill' />
                            )
                          : (
                            <RiCheckboxBlankLine />
                            )}
                      </PaymethodOption>
                      <PaymethodName>{t(paymethod?.gateway?.toUpperCase(), paymethod?.name)}</PaymethodName>
                      {!isTutorialMode && isCheckFoundBusinessPaymethod(paymethod.id) && (
                        <ChevronRight />
                      )}
                    </PaymethodOptionContainer>
                  ))}
                </div>
              </CreditCardPaymentsContainer>
              {isWalletCashEnabled && (
                <BusinessWalletsList
                  business={business}
                  setIsOpenWalletDetails={setIsOpenWalletDetails}
                  setIsExtendExtraOpen={setIsExtendExtraOpen}
                  isClose={isEdit}
                  handleClosePaymethodDetails={handleCloseEdit}
                  handleSuccessUpdate={handleSuccessUpdate}
                />
              )}
            </PaymethodListWrapper>
            )}

        {isTutorialMode && (
          <Button
            borderRadius='8px'
            color='primary'
            onClick={() => handleTutorialContinue()}
          >
            {t('CONTINUE', 'Continue')}
          </Button>
        )}
      </PaymentMethodsContainer>
      {width >= 1000
        ? (
          <>
            {isEdit && (
              <>
                {
                  !['paypal',
                    'paypal_express',
                    'stripe_connect',
                    'square',
                    'credomatic',
                    'stripe_checkout',
                    ...globalPayments,
                    ...methodsPay
                  ].includes(selectedPaymethodGateway) && (
                    <PaymentOption
                      sitesState={sitesState}
                      open={isEdit}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                      deviceState={deviceState}
                      selectedPaymethodGateway={selectedPaymethodGateway}
                    />
                  )
                }
                {selectedPaymethodGateway === 'apple_pay' && (
                  <PaymentOptionMethods
                    sitesState={sitesState}
                    open={isEdit}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    id={selectedPaymethodGateway}
                    title='Apple pay'
                  />
                )}
                {selectedPaymethodGateway === 'google_pay' && (
                  <PaymentOptionMethods
                    sitesState={sitesState}
                    open={isEdit}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    id={selectedPaymethodGateway}
                    title='Google pay'
                  />
                )}
                {stripeDirectMethods.includes(selectedPaymethodGateway) && (
                  <PaymentOptionStripeDirect
                    paymethodTitle={selectedPaymethodGateway}
                    sitesState={sitesState}
                    open={isEdit}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                  />
                )}
                {selectedPaymethodGateway === 'paypal' && (
                  <PaymentOptionPaypal
                    open={isEdit}
                    sitesState={sitesState}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                  />
                )}
                {selectedPaymethodGateway === 'paypal_express' && (
                  <PaymethodOptionPaypalExpress
                    open={isEdit}
                    sitesState={sitesState}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                  />
                )}
                {globalPayments?.includes(selectedPaymethodGateway) && (
                  <PaymentOptionGlobal
                    sitesState={sitesState}
                    open={isEdit}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    selectedPaymethodGateway={selectedPaymethodGateway}
                  />
                )}
                {selectedPaymethodGateway === 'stripe_connect' && (
                  <PaymethodOptionStripeConnect
                    open={isEdit}
                    sitesState={sitesState}
                    business={business}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={stripeConnectData}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeStripeConnectData}
                    actionState={actionState}
                    handleStripeConnect={handleStripeConnect}
                    handleChangeStripeInput={handleChangeStripeInput}
                    handleStripeSave={handleStripeSave}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                  />
                )}
                {selectedPaymethodGateway === 'square' && (
                  <PaymentOptionSquare
                    open={isEdit}
                    sitesState={sitesState}
                    business={business}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    deviceState={deviceState}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    businessPaymethods={businessPaymethodsState?.paymethods}
                    handleSuccessPaymethodUpdate={handleSuccessPaymethodUpdate}
                  />
                )}
                {selectedPaymethodGateway === 'credomatic' && (
                  <PaymentOptionCredomatic
                    open={isEdit}
                    sitesState={sitesState}
                    onClose={() => handleCloseEdit()}
                    businessPaymethod={selectedBusinessPaymethod}
                    changesState={changesState}
                    orderTypes={orderTypes}
                    handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                    cleanChangesState={cleanChangesState}
                    actionState={actionState}
                    handleChangeSandbox={handleChangeSandbox}
                    handleChangeInput={handleChangeInput}
                    handleSaveClick={handleSaveClick}
                    handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                  />
                )}
              </>
            )}
          </>
          )
        : (
          <>
            {isEdit && (
              <>
                <Modal
                  width='80%'
                  open={isEdit}
                  onClose={() => handleCloseEdit()}
                >
                  {
                    !['paypal',
                      'paypal_express',
                      'stripe_connect',
                      'square',
                      'credomatic',
                      'stripe_checkout',
                      ...globalPayments,
                      ...methodsPay
                    ].includes(selectedPaymethodGateway) && (
                      <PaymentOption
                        sitesState={sitesState}
                        open={isEdit}
                        onClose={() => handleCloseEdit()}
                        businessPaymethod={selectedBusinessPaymethod}
                        changesState={changesState}
                        orderTypes={orderTypes}
                        handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                        cleanChangesState={cleanChangesState}
                        actionState={actionState}
                        handleChangeSandbox={handleChangeSandbox}
                        handleChangeInput={handleChangeInput}
                        handleSaveClick={handleSaveClick}
                        handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                      />
                    )
                  }
                  {(methodsPay.includes(selectedPaymethodGateway)) && (
                    <PaymentOptionMethods
                      sitesState={sitesState}
                      open={isEdit}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                      id={selectedPaymethodGateway}
                      title={capitalize(selectedPaymethodGateway).replace('_', ' ')}
                    />
                  )}
                  {stripeDirectMethods.includes(selectedPaymethodGateway) && (
                    <PaymentOptionStripeDirect
                      paymethodTitle={selectedPaymethodGateway}
                      sitesState={sitesState}
                      open={isEdit}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    />
                  )}
                  {selectedPaymethodGateway === 'paypal' && (
                    <PaymentOptionPaypal
                      open={isEdit}
                      sitesState={sitesState}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    />
                  )}
                  {selectedPaymethodGateway === 'paypal_express' && (
                    <PaymethodOptionPaypalExpress
                      open={isEdit}
                      sitesState={sitesState}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    />
                  )}
                  {globalPayments?.includes(selectedPaymethodGateway) && (
                    <PaymentOptionGlobal
                      sitesState={sitesState}
                      open={isEdit}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                      selectedPaymethodGateway={selectedPaymethodGateway}
                    />
                  )}
                  {selectedPaymethodGateway === 'stripe_connect' && (
                    <PaymethodOptionStripeConnect
                      open={isEdit}
                      sitesState={sitesState}
                      business={business}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleStripeConnect={handleStripeConnect}
                      handleChangeStripeInput={handleChangeStripeInput}
                      handleStripeSave={handleStripeSave}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    />
                  )}
                  {selectedPaymethodGateway === 'square' && (
                    <PaymentOptionSquare
                      open={isEdit}
                      sitesState={sitesState}
                      business={business}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      businessPaymethods={businessPaymethodsState?.paymethods}
                      handleSuccessPaymethodUpdate={handleSuccessPaymethodUpdate}
                    />
                  )}
                  {selectedPaymethodGateway === 'credomatic' && (
                    <PaymentOptionCredomatic
                      open={isEdit}
                      sitesState={sitesState}
                      onClose={() => handleCloseEdit()}
                      businessPaymethod={selectedBusinessPaymethod}
                      changesState={changesState}
                      orderTypes={orderTypes}
                      handleChangeBusinessPaymentState={handleChangeBusinessPaymentState}
                      cleanChangesState={cleanChangesState}
                      actionState={actionState}
                      handleChangeSandbox={handleChangeSandbox}
                      handleChangeInput={handleChangeInput}
                      handleSaveClick={handleSaveClick}
                      handleDeletePaymethod={handleDeleteBusinessPaymethodOption}
                    />
                  )}
                </Modal>
              </>
            )}
          </>
          )}
      <Alert
        title={t('PAYMETHODS', 'Payment methods')}
        content={alertState.content}
        acceptText={t('ACCEPT', 'Accept')}
        open={alertState.open}
        onClose={() => closeAlert()}
        onAccept={() => closeAlert()}
        closeOnBackdrop={false}
      />
    </MainContainer>
  )
}

export const BusinessPaymentMethods = (props) => {
  const businessPaymethodProps = {
    ...props,
    UIComponent: BusinessPaymentMethodsUI
  }
  return <BusinessPaymentMethodsController {...businessPaymethodProps} />
}
