import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { Settings as SettingsController, SettingsMenuOptionDetails as SettingsMenuOptionController } from '~ui'

export const PluginSettings = (props) => {
  const isUseSettingComponents = props?.useSettingComponents ?? false
  const pluginSettingsProps = {
    ...props,
    settingsType: 'key_plugin'
  }
  const ControllerComponent = isUseSettingComponents ? SettingsController : SettingsMenuOptionController
  return (
    <>
      <HelmetTags page='plugin_settings' />
      <ControllerComponent {...pluginSettingsProps} />
    </>
  )
}
