import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { useLanguage } from '~components'
import { FilterSettings as FilterSettingsController, SettingsMenuOptionDetails as SettingsMenuOptionController } from '~ui'

export const ProSettings = (props) => {
  const [, t] = useLanguage()
  const isUseFilterComponent = props?.useFilterComponent ?? false
  let proSettingsProps = {
    ...props,
    settingsType: 'key_pro'
  }
  if (isUseFilterComponent) {
    const filterProps = {
      allowOptions: [],
      pageURL: 'pro_settings',
      title: t('PRO_SETTINGS', 'Pro Settings'),
      description: t('PRO_SETTINGS_DESCRIPTION', 'Pro Settings')
    }
    proSettingsProps = {
      ...proSettingsProps,
      ...filterProps
    }
  }

  const ControllerComponent = isUseFilterComponent ? FilterSettingsController : SettingsMenuOptionController

  return (
    <>
      <HelmetTags page='pro_settings' />
      <ControllerComponent {...proSettingsProps} />
    </>
  )
}
