import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { SettingsMenuOptionDetails as SettingsController } from '~ui'

export const StandardSettings = (props) => {
  const standardSettingsProps = {
    ...props,
    settingsType: 'key_basic'
  }
  return (
    <>
      <HelmetTags page='standard_settings' />
      <SettingsController {...standardSettingsProps} />
    </>
  )
}
