import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSession } from '../../contexts/SessionContext'
import { useApi } from '../../contexts/ApiContext'
import { useConfig } from '../../contexts/ConfigContext'

/**
 * Component to manage AllSettings page behavior without UI component
 */
export const AllSettings = (props) => {
  const { UIComponent } = props

  const [{ token, loading }] = useSession()
  const [ordering] = useApi()
  const [{ configs, loading: loadingConfigs }] = useConfig()

  const isProjectProPlan = configs?.plan_pro && configs?.plan_pro?.value
  const isProjectEnterpricePlan = configs?.plan_enterprise && configs?.plan_enterprise?.value

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const [categoryList, setCategoryList] = useState({
    key_basic: [],
    key_pro: [],
    key_enterprise: [],
    key_plugin: [],
    loading: true,
    error: null
  })

  const [keysToFetch, setKeysToFetch] = useState({
    keys: [
      { key: 'key_basic', enabled: true },
      { key: 'key_plugin', enabled: true }
    ],
    loading: true
  })

  const getParentCategories = async () => {
    try {
      const _categories = {}

      for (const key of keysToFetch.keys.filter(k => k.enabled)) {
        const parentCategoryId = await _getParentCategory(key.key)
        if (parentCategoryId) {
          const categories = await _getCategories(parentCategoryId)
          _categories[key.key] = categories
        }
      }

      setCategoryList({ ...categoryList, ..._categories, loading: false })
    } catch (error) {
      setCategoryList({
        ...categoryList,
        loading: false,
        error
      })
    }
  }

  const _getParentCategory = async (key) => {
    try {
      const filterConditons = []
      filterConditons.push({ attribute: 'key', value: key })
      const functionFetch = `${ordering.root}/config_categories?where=${JSON.stringify(filterConditons)}`

      const response = await fetch(functionFetch, fetchOptions)
      const { error, result } = await response.json()
      return error ? null : result[0]?.id
    } catch {
      return null
    }
  }

  const _getCategories = async (parentCategoryId) => {
    try {
      const filterConditons = []
      filterConditons.push({ attribute: 'parent_category_id', value: parseInt(parentCategoryId, 10) })
      const functionFetch = `${ordering.root}/config_categories?orderBy=rank&where=${JSON.stringify(filterConditons)}`

      const response = await fetch(functionFetch, fetchOptions)
      const { error, result } = await response.json()
      const rankResult = result?.sort((a, b) => (a?.rank && b?.rank) ? a.rank - b.rank : 0)
      return error ? null : rankResult
    } catch {
      return null
    }
  }

  useEffect(() => {
    if (loadingConfigs) return
    if (!isProjectProPlan && !isProjectEnterpricePlan) {
      setKeysToFetch({ ...keysToFetch, loading: false })
      return
    }
    setKeysToFetch({
      keys: [
        ...keysToFetch.keys,
        { key: 'key_pro', enabled: isProjectProPlan || isProjectEnterpricePlan },
        { key: 'key_enterprise', enabled: isProjectEnterpricePlan }
      ],
      loading: false
    })
  }, [isProjectProPlan, isProjectEnterpricePlan, loadingConfigs])

  useEffect(() => {
    if (!keysToFetch.loading && !loading) {
      getParentCategories()
    }
  }, [keysToFetch.loading, loading])

  return (
    <>
      {UIComponent && (
        <UIComponent
          {...props}
          isProjectProPlan={isProjectProPlan}
          isProjectEnterpricePlan={isProjectEnterpricePlan}
          basicCategoryList={categoryList.key_basic}
          proCategoryList={categoryList.key_pro}
          enterpriseCategoryList={categoryList.key_enterprise}
          pluginsCategoryList={categoryList.key_plugin}
          loadingCategories={categoryList.loading}
        />
      )}
    </>
  )
}

AllSettings.propTypes = {
  /**
   * UI Component, this must be containt all graphic elements and use parent props
   */
  UIComponent: PropTypes.elementType
}
